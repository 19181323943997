import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Box, Checkbox, Text } from '@chakra-ui/react'
import { ArrowDownIcon } from '../assets/ArrowDownIcon'
import { useContext } from 'react'
import { LanguageContext } from '../locales/LocaleContext'
import { getDirection } from '../locales/locale'

export type DropdownProps = {
	triggerLabel: string
	items: { id: string; label: string; checked: boolean; onChange: () => void }[]
	trigger?: React.ReactNode
}

const Dropdown: React.FC<DropdownProps> = ({ triggerLabel, items, trigger }) => {
	const { locale } = useContext(LanguageContext)
	const dir = getDirection(locale)

	return (
		<DropdownMenu.Root>
			<DropdownMenu.Trigger
				style={{ zIndex: 1000 }}
				dir={dir}
				asChild
			>
				{trigger || (
					<Box
						as='button'
						bg='white'
						height='54px'
						border='1px solid #e2e2e2'
						rounded='10px'
						width='220px'
						display='flex'
						alignItems='center'
						justifyContent='space-between'
						padding='12px 17px'
					>
						<Box width='calc(100% - 20px)'>
							<Text
								textOverflow='ellipsis'
								width='100%'
								overflow='hidden'
								whiteSpace='nowrap'
								textAlign='start'
							>
								{triggerLabel}
							</Text>
						</Box>
						<Box flexShrink={0}>
							<ArrowDownIcon />
						</Box>
					</Box>
				)}
			</DropdownMenu.Trigger>

			<DropdownMenu.Content
				style={{
					backgroundColor: 'white',
					borderRadius: '10px',
					boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.1)',
					maxHeight: '300px',
					overflowY: 'auto',
					minWidth: 200,
					zIndex: 2000,
					direction: dir,
					padding: 10,
				}}
			>
				{items.map((item) => (
					<DropdownMenu.Item
						key={item.id}
						onSelect={(e) => {
							e.preventDefault()
							item.onChange
						}}
						asChild
					>
						<Box
							as='div'
							display='flex'
							alignItems='center'
							padding='8px 12px'
							cursor='pointer'
							_hover={{ backgroundColor: '#F5F8FA' }}
						>
							<Checkbox
								colorScheme='white'
								borderColor='#3FB2D2'
								iconColor='#3FB2D2'
								iconSize='17px'
								size='lg'
								mr={2}
								isChecked={item.checked}
								onChange={item.onChange}
							>
								{item.label}
							</Checkbox>
						</Box>
					</DropdownMenu.Item>
				))}
			</DropdownMenu.Content>
		</DropdownMenu.Root>
	)
}

export default Dropdown
