import {
  Box,
  Button,
  Container,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useMediaQuery,
  Flex,
  Skeleton,
  VStack,
  SimpleGrid,
  Input,
  Switch,
  Select,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowDownIcon } from '../../assets/ArrowDownIcon';
import Swal from 'sweetalert2';
import { appConfig } from '../../configs/app';
import newslatterService from '../../services/newslatter-service';
import UserService from '../../services/user-service';
import style from '../../assets/style.json';
import ImageViewer from 'react-simple-image-viewer';
import { TrashIcon } from '../../assets/TrashIcon';
import { CirclePlusIcon } from '../../assets/CirclePlusIcon';
import { MenuIcon } from '../../assets/MenuIcon';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../locales/LocaleContext';
import { EditIcon2 } from '../../assets/EditIcon2';
import { useForm, SubmitHandler } from 'react-hook-form';
import redButtonSchema from '../../schemas/red-button.schema';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { getMimeType } from '../../services/mime';
import FileSaver from 'file-saver';

const RedButton = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState<any>({});
  const [newslatter, setNewslatter] = useState<any[]>([]);
  const [images, setImages] = useState<string[]>([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isPc] = useMediaQuery('(min-width: 1300px)');

  const navigate = useNavigate();

  const [isSubmited, setIsSubmited] = useState(false);

  const { isLoading, setIsLoading } = useContext(LanguageContext);

  useEffect(() => {
    load();
  }, []);

  const getImageLink = (filePath: string) => {
    return `${
      appConfig.apiUrl
    }/file?path=${filePath}&token=${localStorage.getItem('token')}`;
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const load = async () => {
    setIsLoading(true); // Set loading state to true
    try {
      const user = await UserService.info();
      setUserInfo(user);
      const newslatter = await newslatterService.list();
      setNewslatter(newslatter.reverse());

      const images = newslatter.map((post: any) => {
        return post.filePath ? getImageLink(post.filePath) : '';
      });

      setImages(images);
    } finally {
      setIsLoading(false); // Set loading state to false after data is fetched
    }
  };

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<z.infer<typeof redButtonSchema>>({
    resolver: zodResolver(redButtonSchema),
  });

  console.log({ errors });

  const [downloadPath, setDownloadPath] = useState('');
  const downloadFile = async (fileName: string, path: string) => {
    /*
    DO
    NOT
    TOUCH
    */
    console.log(fileName);
    console.log(path);

    // now you can touch
    setDownloadPath(path);
    try {
      let response = await fetch(
        `${appConfig.apiUrl}/file?path=${path}&token=${localStorage.getItem(
          'token',
        )}&fileName=${fileName}`,
      );
      let data = await response.blob();

      let splittedFileName = fileName.split('.');
      let extension = splittedFileName.at(splittedFileName.length - 1);
      console.log('before');

      let fixedData = new Blob([data], { type: getMimeType(extension ?? '') });
      console.log('after');
      FileSaver.saveAs(fixedData, fileName);
    } finally {
      setDownloadPath('');
    }
  };

  console.log({ errors, values: getValues(), isSubmited });

  const onSubmit = async (data: z.infer<typeof redButtonSchema>) => {
    console.log(data);
    if (isSubmited) return;
    setIsSubmited(true);

    try {
      const { data: file } = await axios.post(
        `${appConfig.apiUrl}/red-button`,
        data,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );

      await downloadFile(`red-button.pdf`, file);
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  };

  const handleDemoData = () => {
    // setValue('reporterName', 'אמיר');
    // setValue('reporterRole', 'מנהל משמרת');
    // setValue('date', '2022-01-01');
    setValue('subjectLastName', 'גון');
    setValue('subjectFirstName', 'דו');
    // setValue('subjectId', '123456789');
    // setValue('subjectSex', 'זכר');
    // setValue('subjectBirthDate', '1990-01-01');
    // setValue('subjectMaritalStatus', 'נשוי');
    // setValue('subjectKidsAmount', '2');
    // setValue('subjectAddressStreet', 'הרצל');
    // setValue('subjectAddressStreetNumber', '123');
    // setValue('subjectAddressCity', 'בני ברק');
    // setValue('subjectAddressZip', '12345');
    // setValue('subjectPhone', '0526694345');
    // setValue(
    //   'distinctions',
    //   'לורם איפסום דולור סיט אמט קונסקטטור אפיסינג אליט',
    // );
    // setValue('beforeEventConsciousnessEnum', 'full');
    // setValue('subjectBeforeEventConsciousnessOther', 'Other');
    // setValue('fallMobilityBeforeEventEnum', 'bedridden');
    // setValue('fallMobilityBed', 'risen');
    setValue('eventDate', '2022-01-01');
    setValue('eventTime', '12:00');
    // setValue('eventDay', 'SATURDAY');
    // setValue('shiftTime', 'MORNING');
    // setValue('eventLocation', 'PLATOON');
    // setValue('eventLocationSpecific', 'Specific location');
    setValue('eventDescription', 'Description');
    setValue('eventResults', 'Results');
    // setValue('staffInvolvedName1', 'John Doe');
    // setValue('staffInvolvedRole1', 'Role');
    // setValue('staffInvolvedName2', 'Jane Doe');
    // setValue('staffInvolvedRole2', 'Role');
  };

  return (
    <Container
      maxW="full"
      minH="100vh"
      position={'absolute'}
      top={'90px'}
      py={20}
      centerContent
    >
      {isLoading ? (
        <>
          <Flex justify="center">
            <Skeleton height="30px" width="200px" borderRadius="md" />
          </Flex>
        </>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          onChange={() => setIsSubmited(false)}
        >
          <Text color={'white'} dir="ltr" fontWeight={500} fontSize={22}>
            {t('newsletter.hello') + ' ' + userInfo.name}
          </Text>
          <Text
            color={'white'}
            dir="rtl"
            marginBottom={'0px'}
            fontWeight={500}
            fontSize={22}
          >
            {t('newsletter.peaceOfMind')}
          </Text>

          <>
            {isViewerOpen && (
              <ImageViewer
                src={images}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
          </>

          <Box
            padding={'22px'}
            width={'100%'}
            maxW={style['pc-view']}
            rounded="10px"
            bg={'#fff'}
            w="100%"
            boxShadow="0px 0px 25px rgba(62, 62, 62, 0.08);"
            mt={10}
            paddingInline={'28px'}
            display={'flex'}
            flexDirection="column"
            gap={'20px'}
          >
            {/* <Box bg="#F5F8FA" p={'21px'} rounded="10px">
              <Text fontSize={'22px'} fontWeight="600" mb={'22px'}>
                {t('redButton.reporterTitle')}
              </Text>
              <VStack w="100%" spacing={2}>
                <SimpleGrid
                  w="100%"
                  columns={{ base: 1, md: 3 }}
                  spacingX={style.gap}
                  spacingY={style.gap}
                >
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.reporterName')}
                    </Text>
                    <Input
                      bg={'#fff'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      height={'54px'}
                      maxLength={50}
                      placeholder={t('redButton.reporterName')}
                      {...register('reporterName')}
                    />
                    <Error
                      visible={!!errors.reporterName}
                      text={t('redButton.reporterNameError')}
                    />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.reporterRole')}
                    </Text>
                    <Input
                      maxLength={30}
                      bg={'#fff'}
                      height={'54px'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      placeholder={t('redButton.reporterRole')}
                      {...register('reporterRole')}
                    />
					<Error visible={!!errors.reporterRole} text={t('redButton.reporterRoleError')}
                    />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.reporterHostNum')}
                    </Text>
                    <Input
                      maxLength={30}
                      bg={'#fff'}
                      height={'54px'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      placeholder={t('redButton.reporterHostNum')}
                      {...register('reporterHostNum')}
                    />
					<Error visible={!!errors.reporterHostNum} text={t('redButton.reporterHostNumError')} />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.date')}
                    </Text>
                    <Input
                      maxLength={30}
                      bg={'#fff'}
                      height={'54px'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      placeholder={t('redButton.date')}
                      type="date"
                      {...register('date')}
                    />
					<Error visible={!!errors.date} text={t('redButton.dateError')}  />
                  </Box>
                </SimpleGrid>
              </VStack>
            </Box> */}
            <Box bg="#F5F8FA" p={'21px'} rounded="10px">
              <Text fontSize={'22px'} fontWeight="600" mb={'22px'}>
                {t('redButton.subject')}
              </Text>
              <VStack w="100%" spacing={2}>
                <SimpleGrid
                  w="100%"
                  columns={{ base: 1, md: 3 }}
                  spacingX={style.gap}
                  spacingY={style.gap}
                >
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.subjectLastName')}
                    </Text>
                    <Input
                      bg={'#fff'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      height={'54px'}
                      maxLength={50}
                      placeholder={t('redButton.subjectLastName')}
                      {...register('subjectLastName')}
                    />
                    <Error
                      visible={!!errors.subjectLastName}
                      text={t('redButton.subjectLastNameError')}
                    />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.subjectFirstName')}
                    </Text>
                    <Input
                      maxLength={30}
                      bg={'#fff'}
                      height={'54px'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      placeholder={t('redButton.subjectFirstName')}
                      {...register('subjectFirstName')}
                    />
                    <Error
                      visible={!!errors.subjectFirstName}
                      text={t('redButton.subjectFirstNameError')}
                    />
                  </Box>
                  {/* <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.subjectId')}
                    </Text>
                    <Input
                      maxLength={30}
                      bg={'#fff'}
                      height={'54px'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      placeholder={t('redButton.subjectId')}
                      {...register('subjectId')}
                    />
					<Error visible={!!errors.subjectId} text={t('redButton.subjectIdError')} />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.subjectSex')}
                    </Text>
                    <RadioGroup
                      defaultValue={getValues('subjectSex')}
                      onChange={(value) => setValue('subjectSex', value)}
                    >
                      <Stack direction="row">
                        <Radio value="זכר">
                          {t('redButton.subjectSexMale')}
                        </Radio>
                        <Radio value="נקבה">
                          {t('redButton.subjectSexFemale')}
                        </Radio>
                      </Stack>
                    </RadioGroup>
					<Error visible={!!errors.subjectSex} text={t('redButton.subjectSexError')} />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.subjectBirthDate')}
                    </Text>
                    <Input
                      maxLength={30}
                      bg={'#fff'}
                      height={'54px'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      type="number"
                      placeholder={t('redButton.subjectBirthDate')}
                      {...register('subjectBirthDate')}
                    />
					<Error visible={!!errors.subjectBirthDate} text={t('redButton.subjectBirthDateError')} />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.subjectMaritalStatus')}
                    </Text>
                    <Input
                      maxLength={30}
                      bg={'#fff'}
                      height={'54px'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      type="text"
                      placeholder={t('redButton.subjectMaritalStatus')}
                      {...register('subjectMaritalStatus')}
                    />
					<Error visible={!!errors.subjectMaritalStatus} text={t('redButton.subjectMaritalStatusError')} />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.subjectKidsAmount')}
                    </Text>
                    <Input
                      maxLength={30}
                      bg={'#fff'}
                      height={'54px'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      type="number"
                      placeholder={t('redButton.subjectKidsAmount')}
                      {...register('subjectKidsAmount')}
                    />
					<Error visible={!!errors.subjectKidsAmount} text={t('redButton.subjectKidsAmountError')} />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.subjectAddressStreet')}
                    </Text>
                    <Input
                      maxLength={30}
                      bg={'#fff'}
                      height={'54px'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      placeholder={t('redButton.subjectAddressStreet')}
                      {...register('subjectAddressStreet')}
                    />
					<Error visible={!!errors.subjectAddressStreet} text={t('redButton.subjectAddressStreetError')} />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.subjectAddressStreetNumber')}
                    </Text>
                    <Input
                      maxLength={30}
                      bg={'#fff'}
                      height={'54px'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      type="number"
                      placeholder={t('redButton.subjectAddressStreetNumber')}
                      {...register('subjectAddressStreetNumber')}
                    />
					<Error visible={!!errors.subjectAddressStreetNumber} text={t('redButton.subjectAddressStreetNumberError')} />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.subjectAddressCity')}
                    </Text>
                    <Input
                      maxLength={30}
                      bg={'#fff'}
                      height={'54px'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      placeholder={t('redButton.subjectAddressCity')}
                      {...register('subjectAddressCity')}
                    />
					<Error visible={!!errors.subjectAddressCity} text={t('redButton.subjectAddressCityError')} />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.subjectAddressZip')}
                    </Text>
                    <Input
                      maxLength={30}
                      bg={'#fff'}
                      height={'54px'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      type="number"
                      placeholder={t('redButton.subjectAddressZip')}
                      {...register('subjectAddressZip')}
                    />
					<Error visible={!!errors.subjectAddressZip} text={t('redButton.subjectAddressZipError')} />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.subjectPhone')}
                    </Text>
                    <Input
                      maxLength={30}
                      bg={'#fff'}
                      height={'54px'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      type="number"
                      placeholder={t('redButton.subjectPhone')}
                      {...register('subjectPhone')}
                    />
					<Error visible={!!errors.subjectPhone} text={t('redButton.subjectPhoneError')} />
                  </Box> */}
                </SimpleGrid>
              </VStack>
            </Box>
            {/* <Box bg="#F5F8FA" p={'21px'} rounded="10px">
              <Text fontSize={'22px'} fontWeight="600" mb={'22px'}>
                {t('redButton.distinctions')}
              </Text>
              <VStack w="100%" spacing={2}>
                <Box w="100%">
                  <Text
                    marginBottom={'5px'}
                    fontSize={'14px'}
                    color="#aaa"
                    fontWeight="14px"
                  >
                    {t('redButton.distinctions')}
                  </Text>
                  <Textarea
                    bg={'#fff'}
                    border={'1px solid #E2E2E2'}
                    rounded="10px"
                    height={'54px'}
                    placeholder={t('redButton.distinctions')}
                    {...register('distinctions')}
                  />
				  <Error visible={!!errors.distinctions} text={t('redButton.distinctionsError')} />
                </Box>
              </VStack>
            </Box> */}
            {/* <Box bg="#F5F8FA" p={'21px'} rounded="10px">
              <Text fontSize={'22px'} fontWeight="600" mb={'22px'}>
                {t('redButton.beforeEventConsciousness')}
              </Text>
              <VStack w="100%" spacing={2}>
                <SimpleGrid
                  w="100%"
                  columns={{ base: 1, md: 2 }}
                  spacingX={style.gap}
                  spacingY={style.gap}
                >
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.beforeEventConsciousness')}
                    </Text>
                    <Select
                      width="100%"
                      background={'white'}
                      onChange={(e) =>
                        setValue(
                          'beforeEventConsciousnessEnum',
                          e.target.value as any,
                        )
                      }
                      defaultValue={getValues('beforeEventConsciousnessEnum')}
                    >
                      {[
                        'full',
                        'partial',
                        'confused',
                        'unconscious',
                        'other',
                      ].map((item) => (
                        <option
                          value={item}
                          style={{ color: '#000' }}
                          key={item}
                        >
                          {t(`redButton.beforeEventConsciousnessEnum.${item}`)}
                        </option>
                      ))}
                    </Select>
					<Error visible={!!errors.beforeEventConsciousnessEnum} text={t('redButton.beforeEventConsciousnessEnumError')} />
                  </Box>
                  {getValues('beforeEventConsciousnessEnum') === 'other' && (
                    <Box>
                      <Text
                        marginBottom={'5px'}
                        fontSize={'14px'}
                        color="#aaa"
                        fontWeight="14px"
                      >
                        {t('redButton.subjectBeforeEventConsciousnessOther')}
                      </Text>
                      <Input
                        bg={'#fff'}
                        border={'1px solid #E2E2E2'}
                        rounded="10px"
                        height={'54px'}
                        maxLength={50}
                        placeholder={t(
                          'redButton.subjectBeforeEventConsciousnessOther',
                        )}
                        {...register('subjectBeforeEventConsciousnessOther')}
                      />
					  <Error visible={!!errors.subjectBeforeEventConsciousnessOther} text={t('redButton.subjectBeforeEventConsciousnessOtherError')} />
                    </Box>
                  )}
                </SimpleGrid>
              </VStack>
            </Box> */}
            {/* <Box bg="#F5F8FA" p={'21px'} rounded="10px">
              <Text fontSize={'22px'} fontWeight="600" mb={'2px'}>
                {t('redButton.fallMobilityBeforeEvent')}
              </Text>
              <Text fontSize={'16px'} fontWeight="400" mb={'14px'}>
                {t('redButton.fallMobilityBeforeEventNote')}
              </Text>
              <VStack w="100%" spacing={2}>
                <SimpleGrid
                  w="100%"
                  columns={{ base: 1, md: 2 }}
                  spacingX={style.gap}
                  spacingY={style.gap}
                >
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.fallMobilityBeforeEvent')}
                    </Text>
                    <Select
                      width="100%"
                      background={'white'}
                      onChange={(e) =>
                        setValue(
                          'fallMobilityBeforeEventEnum',
                          e.target.value as any,
                        )
                      }
                      defaultValue={
                        getValues('fallMobilityBeforeEventEnum') as string
                      }
                    >
                      {['bedridden', 'limited', 'self', 'self_with_tools'].map(
                        (item) => (
                          <option
                            value={item}
                            style={{ color: '#000' }}
                            key={item}
                          >
                            {t(`redButton.fallMobilityBeforeEventEnum.${item}`)}
                          </option>
                        ),
                      )}
                    </Select>
					<Error visible={!!errors.fallMobilityBeforeEventEnum} text={t('redButton.fallMobilityBeforeEventEnumError')} />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.fallMobilityBed')}
                    </Text>

                    <Select
                      width="100%"
                      background={'white'}
                      onChange={(e) =>
                        setValue('fallMobilityBed', e.target.value as any)
                      }
                      defaultValue={getValues('fallMobilityBed') as string}
                    >
                      {['risen', 'not_risen', 'no_sides'].map((item) => (
                        <option
                          value={item}
                          style={{ color: '#000' }}
                          key={item}
                        >
                          {t(`redButton.fallMobilityBedEnum.${item}`)}
                        </option>
                      ))}
                    </Select>
					<Error visible={!!errors.fallMobilityBed} text={t('redButton.fallMobilityBedError')} />
                  </Box>
                </SimpleGrid>
              </VStack>
            </Box> */}
            <Box bg="#F5F8FA" p={'21px'} rounded="10px">
              <Text fontSize={'22px'} fontWeight="600" mb={'22px'}>
                {t('redButton.eventTitle')}
              </Text>
              <VStack w="100%" spacing={2}>
                <SimpleGrid
                  w="100%"
                  columns={{ base: 1, md: 3 }}
                  spacingX={style.gap}
                  spacingY={style.gap}
                >
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.eventDate')}
                    </Text>
                    <Input
                      bg={'#fff'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      height={'54px'}
                      maxLength={50}
                      type="date"
                      placeholder={t('redButton.eventDate')}
                      {...register('eventDate')}
                    />
                    <Error
                      visible={!!errors.eventDate}
                      text={t('redButton.eventDateError')}
                    />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.eventTime')}
                    </Text>
                    <Input
                      maxLength={30}
                      bg={'#fff'}
                      height={'54px'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      type="time"
                      placeholder={t('redButton.eventTime')}
                      {...register('eventTime')}
                    />
                    <Error
                      visible={!!errors.eventTime}
                      text={t('redButton.eventTimeError')}
                    />
                  </Box>
                  {/* <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.eventDay')}
                    </Text>
                    <Select
                      width="100%"
                      background={'white'}
                      onChange={(e) =>
                        setValue('eventDay', e.target.value as any)
                      }
                      defaultValue={getValues('eventDay')}
                    >
                      {['SATURDAY', 'WEEKDAY', 'HOLIDAY'].map((item) => (
                        <option
                          value={item}
                          style={{ color: '#000' }}
                          key={item}
                        >
                          {t(`redButton.eventDayEnum.${item}`)}
                        </option>
                      ))}
                    </Select>
					<Error visible={!!errors.eventDay} text={t('redButton.eventDayError')} />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.shiftTime')}
                    </Text>
                    <Select
                      width="100%"
                      background={'white'}
                      onChange={(e) =>
                        setValue('shiftTime', e.target.value as any)
                      }
                      defaultValue={getValues('shiftTime')}
                    >
                      {['MORNING', 'AFTERNOON', 'NIGHT'].map((item) => (
                        <option
                          value={item}
                          style={{ color: '#000' }}
                          key={item}
                        >
                          {t(`redButton.shiftTimeEnum.${item}`)}
                        </option>
                      ))}
                    </Select>
					<Error visible={!!errors.shiftTime} text={t('redButton.shiftTimeError')} />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.eventLocation')}
                    </Text>
                    <Select
                      width="100%"
                      background={'white'}
                      onChange={(e) =>
                        setValue('eventLocation', e.target.value as any)
                      }
                      defaultValue={getValues('eventLocation')}
                    >
                      {['PLATOON', 'CLINIC', 'INSTITUTE'].map((item) => (
                        <option
                          value={item}
                          style={{ color: '#000' }}
                          key={item}
                        >
                          {t(`redButton.eventLocationEnum.${item}`)}
                        </option>
                      ))}
                    </Select>
					<Error visible={!!errors.eventLocation} text={t('redButton.eventLocationError')} />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.eventLocationSpecific')}
                    </Text>
                    <Input
                      bg={'#fff'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      placeholder={t('redButton.eventLocationSpecific')}
                      height={'54px'}
                      {...register('eventLocationSpecific')}
                    />
					<Error visible={!!errors.eventLocationSpecific} text={t('redButton.eventLocationSpecificError')} />
                  </Box> */}
                  <Box className="col-span-3">
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.eventDescription')}
                    </Text>
                    <Textarea
                      bg={'#fff'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      placeholder={t('redButton.eventDescription')}
                      {...register('eventDescription')}
                    />
                    <Error
                      visible={!!errors.eventDescription}
                      text={t('redButton.eventDescriptionError')}
                    />
                  </Box>
                  <Box className="col-span-3">
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.eventResults')}
                    </Text>
                    <Textarea
                      bg={'#fff'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      placeholder={t('redButton.eventResults')}
                      {...register('eventResults')}
                    />
                    <Error
                      visible={!!errors.eventResults}
                      text={t('redButton.eventResultsError')}
                    />
                  </Box>
                </SimpleGrid>
              </VStack>
            </Box>
            {/* <Box bg="#F5F8FA" p={'21px'} rounded="10px">
              <Text fontSize={'22px'} fontWeight="600" mb={'22px'}>
                {t('redButton.staffInvolvedTitle')}
              </Text>
              <VStack w="100%" spacing={2}>
                <SimpleGrid
                  w="100%"
                  columns={{ base: 1, md: 2 }}
                  spacingX={style.gap}
                  spacingY={style.gap}
                >
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.staffInvolvedName')}
                    </Text>
                    <Input
                      bg={'#fff'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      height={'54px'}
                      maxLength={50}
                      placeholder={t('redButton.staffInvolvedName')}
                      {...register('staffInvolvedName1')}
                    />
					<Error visible={!!errors.staffInvolvedName1} text={t('redButton.staffInvolvedNameError')} />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.staffInvolvedRole')}
                    </Text>
                    <Input
                      maxLength={30}
                      bg={'#fff'}
                      height={'54px'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      placeholder={t('redButton.staffInvolvedRole')}
                      {...register('staffInvolvedRole1')}
                    />
					<Error visible={!!errors.staffInvolvedRole1} text={t('redButton.staffInvolvedRoleError')} />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.staffInvolvedName')}
                    </Text>
                    <Input
                      bg={'#fff'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      height={'54px'}
                      maxLength={50}
                      placeholder={t('redButton.staffInvolvedName')}
                      {...register('staffInvolvedName2')}
                    />
					<Error visible={!!errors.staffInvolvedName2} text={t('redButton.staffInvolvedNameError')} />
                  </Box>
                  <Box>
                    <Text
                      marginBottom={'5px'}
                      fontSize={'14px'}
                      color="#aaa"
                      fontWeight="14px"
                    >
                      {t('redButton.staffInvolvedRole')}
                    </Text>
                    <Input
                      maxLength={30}
                      bg={'#fff'}
                      height={'54px'}
                      border={'1px solid #E2E2E2'}
                      rounded="10px"
                      placeholder={t('redButton.staffInvolvedRole')}
                      {...register('staffInvolvedRole2')}
                    />
					<Error visible={!!errors.staffInvolvedRole2} text={t('redButton.staffInvolvedRoleError')} />
                  </Box>
                </SimpleGrid>
              </VStack>
            </Box> */}
            <Button
              color={'white'}
              minW={isPc ? '200px' : 'full'}
              height="60px"
              border="3px solid #FFFFFF"
              background={'#3FB2D2'}
              fontSize="18px"
              fontWeight={700}
              rounded={'100px'}
              filter="drop-shadow(0px 4px 30px rgba(63, 178, 210, 0.44))"
              type="submit"
              alignSelf="end"
              //   isDisabled={isSubmited || !isValid}
              isDisabled={isSubmited}
            >
              {isSubmited ? t('redButton.loading') : t('redButton.approve')}
            </Button>
            {/* <Button
              color={'white'}
              minW={isPc ? '200px' : 'full'}
              height="60px"
              border="3px solid #FFFFFF"
              background={'#3FB2D2'}
              fontSize="18px"
              fontWeight={700}
              rounded={'100px'}
              filter="drop-shadow(0px 4px 30px rgba(63, 178, 210, 0.44))"
              alignSelf="end"
              onClick={handleDemoData}
            >
              DEMO DATA
            </Button> */}
          </Box>
        </form>
      )}
    </Container>
  );
};

const Error = ({ text, visible }: { text?: string; visible: boolean }) => {
  return (
    visible && (
      <Text marginTop={'5px'} fontSize={'12px'} color="red" fontWeight="14px">
        {text}
      </Text>
    )
  );
};

export default RedButton;
