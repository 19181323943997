import type { User } from '../types/server/generics/user'
import { appConfig } from '../configs/app'
import fetcher from '../utils/fetcher'
import axios from 'axios'
import useSWR from 'swr'

export type Question = {
	id?: string
	content: string
	options: string[]
	correctOptionIndexs: number[]
}

export type GuidingQuestionnaireUserDetailed = User & { status: string }

export type GuidingQuestionnaireUser = {
	userId: string
	status: string
}

export type GuidingQuestionnaire = {
	id: string
	name: string
	companyIds: string[]
	roleIds: string[]
	questions: Question[]
	users: GuidingQuestionnaireUser[]
	createdAt: string
	updatedAt: string
}

export const useGuidingQuestionnaireList = () => {
	const fetch = useSWR<GuidingQuestionnaire[]>(`${appConfig.apiUrl}/guiding-questionnaire`, fetcher)

	return {
		...fetch,
		data: fetch.data || [],
	}
}

export const useGuidingQuestionnaireUsers = (id: string) => {
	const fetch = useSWR<GuidingQuestionnaireUserDetailed[]>(
		`${appConfig.apiUrl}/guiding-questionnaire/${id}/users`,
		fetcher
	)

	return {
		...fetch,
		data: fetch.data || [],
	}
}

// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
class GuidingQuestionnaireService {
	static async read(id: string) {
		const response = await axios.get(`${appConfig.apiUrl}/guiding-questionnaire/${id}`, {
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		})

		const data = response.data
		return data as GuidingQuestionnaire
	}

	// static async users(id: string) {
	// 	const response = await axios.get(`${appConfig.apiUrl}/guiding-questionnaire/${id}/users`, {
	// 		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	// 	})

	// 	const data = response.data
	// 	return data as GuidingQuestionnaireUser[]
	// }

	static async delete(id: string) {
		await axios.delete(`${appConfig.apiUrl}/guiding-questionnaire/${id}`, {
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		})
	}

	static async list() {
		const response = await axios.get(`${appConfig.apiUrl}/guiding-questionnaire`, {
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		})
		const data = response.data
		return data as GuidingQuestionnaire[]
	}

	static async modify(questionnaire: Partial<GuidingQuestionnaire> & { id?: string }) {
		console.log({ questionnaire })
		const response = await axios(`${appConfig.apiUrl}/guiding-questionnaire`, {
			data: questionnaire,
			method: questionnaire.id ? 'PUT' : 'POST',
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		})

		const data = response.data

		return data
	}

	static async submitTest(id: string) {
		await axios.post(
			`${appConfig.apiUrl}/guiding-questionnaire/${id}/submit`,
			{},
			{
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			}
		)
	}

	static async viewTest(id: string) {
		await axios.post(
			`${appConfig.apiUrl}/guiding-questionnaire/${id}/view`,
			{},
			{
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			}
		)
	}
}

export default GuidingQuestionnaireService
