import type { OnboardingAnswerController } from '../types/server/endpoints/onboarding-answer'
import { appConfig } from '../configs/app'
import fetcher from '../utils/fetcher'
import axios from 'axios'
import useSWR from 'swr'

export const useOnboardingAnswers = (onboardingId?: string) => {
	const fetch = useSWR<OnboardingAnswerController['getByQuestionnaire']['returns']>(
		onboardingId ? `${appConfig.apiUrl}/onboarding-answer/questionnaire/${onboardingId}` : undefined,
		fetcher
	)

	return {
		...fetch,
		data: fetch.data,
	}
}

export const useCompanyOnboardingAnswers = (companyId?: string) => {
	const fetch = useSWR<OnboardingAnswerController['getByCompany']['returns']>(
		companyId ? `${appConfig.apiUrl}/onboarding-answer/company/${companyId}` : undefined,
		fetcher
	)

	return {
		...fetch,
		data: fetch.data,
	}
}

// class OnboardingAnswersService {
// 	// -> useOnboardingAnswers
// 	static async getOnboardingAnswers(onboardingId: string) {
// 		try {
// 			const response = await axios.get(`${appConfig.apiUrl}/onboarding-answer/questionnaire/${onboardingId}`, {
// 				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
// 			})
// 			return response.data.answers
// 		} catch (error) {
// 			return null
// 		}
// 	}

// 	// -> useCompanyOnboardingAnswers
// 	static async getCompanyOnboardingAnswers(companyId: string) {
// 		try {
// 			const response = await axios.get(`${appConfig.apiUrl}/onboarding-answer/company/${companyId}`, {
// 				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
// 			})
// 			return response.data.answers
// 		} catch (error) {
// 			return null
// 		}
// 	}
// }

// export default OnboardingAnswersService
