import type { EditCompany } from '../../../types/server/generics/company'
import CompanyService, { useListCompanies } from '../../../services/company-service'
import Dropdown from '../../../components/Dropdown'
import PhoneInput from 'react-phone-input-2'
import Swal from 'sweetalert2'
import { Box, Button, Input, Tr, Td, Switch } from '@chakra-ui/react'
import { TrashIcon } from '../../../assets/TrashIcon'
import { useTranslation } from 'react-i18next'
import { toggleIdFromArr } from '.'
import { memo } from 'react'

const Employee = ({
	company,
	employee,
	index,
	setEmployee,
	setCompany,
}: {
	company: EditCompany
	employee: EditCompany['employees'][number]
	index: number
	setEmployee: (company: EditCompany['employees'][number]) => void
	setCompany: (company: EditCompany) => void
}) => {
	const { t } = useTranslation()

	return (
		<Tr
			key={employee.id}
			rounded={'10px'}
			border={'none'}
			position='relative'
		>
			<Td
				minWidth={150}
				roundedRight={'10px'}
				bg={index % 2 === 1 ? 'white' : '#F5F8FA'}
				border={'none'}
				textAlign='start'
				p={1.5}
			>
				<Input
					placeholder={t('modifyCompany.name')}
					borderColor='#E2E2E2'
					bg={'white'}
					rounded={'10px'}
					fontSize='16px'
					fontWeight={400}
					paddingInline='12px'
					paddingBlock='30px'
					value={employee.name}
					onChange={(e) => {
						setEmployee({
							...employee,
							name: e.target.value,
						})
					}}
					maxLength={50}
				/>
			</Td>

			<Td
				bg={index % 2 === 1 ? 'white' : '#F5F8FA'}
				border={'none'}
				textAlign='start'
				p={1.5}
			>
				<PhoneInput
					country={'il'}
					inputClass='login-phone-input'
					containerStyle={{
						minWidth: '250px',
					}}
					value={employee.phone}
					onChange={(phoneNumber) => {
						setEmployee({
							...employee,
							phone: phoneNumber,
						})
					}}
				/>
			</Td>

			<Td
				bg={index % 2 === 1 ? 'white' : '#F5F8FA'}
				border={'none'}
				textAlign='start'
				p={1.5}
			>
				<Input
					placeholder={t('modifyCompany.email')}
					borderColor='#E2E2E2'
					width={'220px'} // was 350
					type='email'
					bg={'white'}
					rounded={'10px'}
					fontSize='16px'
					fontWeight={400}
					paddingInline='12px'
					paddingBlock='30px'
					value={employee.email}
					onChange={(e) => {
						setEmployee({
							...employee,
							email: e.target.value,
						})
					}}
				/>
			</Td>

			<Td
				bg={index % 2 === 1 ? 'white' : '#F5F8FA'}
				border={'none'}
				textAlign='start'
				p={1.5}
			>
				<RolesDropdown
					company={company}
					employee={employee}
					setEmployee={setEmployee}
				/>
			</Td>

			<Td
				bg={index % 2 === 1 ? 'white' : '#F5F8FA'}
				border={'none'}
				textAlign='start'
				p={1.5}
			>
				<CompaniesDropdown
					employee={employee}
					setEmployee={setEmployee}
				/>
			</Td>

			<Td
				minWidth={150}
				bg={index % 2 === 1 ? 'white' : '#F5F8FA'}
				border={'none'}
				textAlign='start'
				fontSize={'16px'}
				fontWeight='400'
				maxWidth={'30%'}
				p={1.5}
			>
				{company?.roles
					?.filter((r) => employee.roleIds?.some((id) => id === r.id))
					.map((role) => role.parentIds?.map((x) => company.roles.find((r) => r.id === x)?.name))
					.reduce<string[]>((acc, rolesNames) => {
						rolesNames?.forEach((r) => {
							if (!acc.some((name) => name === r)) r && acc.push(r)
						})

						return acc
					}, [])
					.join(', ')}
			</Td>

			<Td
				minWidth={50} // was 150
				bg={index % 2 === 1 ? 'white' : '#F5F8FA'}
				border={'none'}
				textAlign='start'
				fontSize={'16px'}
				fontWeight='400'
				maxWidth={'30%'}
				p={1.5}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row-reverse',
					}}
					dir='ltr'
				>
					<Switch
						defaultChecked={employee?.isActive}
						onChange={() => setEmployee({ ...employee, isActive: !employee?.isActive })}
					/>
				</Box>
			</Td>

			<Td
				bg={index % 2 === 1 ? 'white' : '#F5F8FA'}
				border={'none'}
				textAlign='end'
				roundedLeft={'10px'}
				p={1.5}
			>
				<Button
					color='#3FB2D2'
					bg={'transparent'}
					onClick={async () => {
						const result = await Swal.fire({
							title: t('modifyCompany.deleteEmployee') + employee.name + '?',
							text: t('modifyCompany.deleteWarning'),
							input: 'password',
							inputAttributes: {
								autocapitalize: 'off',
								placeholder: t('modifyCompany.enterPassword'),
							},
							showCancelButton: true,
							confirmButtonColor: '#3085d6',
							cancelButtonColor: '#d33',
							cancelButtonText: t('modifyCompany.cancel'),
							confirmButtonText: t('modifyCompany.delete'),
						})
						if (result.isConfirmed) {
							try {
								await CompanyService.checkAdminCode(result.value)

								setCompany({
									...company,
									employees: company.employees.filter((r) => r.id !== employee.id),
								})
							} catch (err) {
								Swal.fire('', t('modifyCompany.invalidPassword'), 'error')
							}
						}
					}}
				>
					<TrashIcon />
				</Button>
			</Td>
		</Tr>
	)
}

export const CompaniesDropdown = memo(
	({
		employee,
		setEmployee,
	}: {
		employee: EditCompany['employees'][number]
		setEmployee: (employee: EditCompany['employees'][number]) => void
	}) => {
		const { data: companies } = useListCompanies()
		const { t } = useTranslation()

		const items = companies.map((company) => ({
			id: company.id,
			label: company.name ? company.name?.length > 40 ? company.name.slice(0, 40) + '...' : company.name : '',
			checked: employee?.subCompanies?.includes(company.id) || false,
			onChange: () =>
				setEmployee({
					...employee,
					subCompanies: toggleIdFromArr(employee.subCompanies || [], company.id),
				}),
		}))

		const triggerLabel =
			companies
				?.filter((r) => employee?.subCompanies?.includes(r.id))
				.map((r) => r.name)
				.join(',') || t('modifyCompany.subCompanies')

		return (
			<Dropdown
				triggerLabel={triggerLabel}
				items={items}
			/>
		)
	}
)

export const RolesDropdown = memo(
	({
		company,
		employee,
		setEmployee,
	}: {
		company: EditCompany
		employee: EditCompany['employees'][number]
		setEmployee: (employee: EditCompany['employees'][number]) => void
	}) => {
		const { t } = useTranslation()

		const items = company?.roles
			?.filter((r) => !!r.name)
			.map((r) => ({
				id: r.id!,
				label: r.name ? r.name?.length > 50 ? r.name.slice(0, 50) + '...' : r.name : '',
				checked: employee?.roleIds?.includes(r.id!) || false,
				onChange: () =>
					setEmployee({
						...employee,
						roleIds: toggleIdFromArr(employee.roleIds || [], r.id!),
					}),
			}))

		const triggerLabel =
			company?.roles
				?.filter((r) => employee.roleIds?.includes(r.id!))
				.map((r) => r.name)
				.join(',') || t('modifyCompany.chooseRole')

		return (
			<Dropdown
				triggerLabel={triggerLabel}
				items={items}
			/>
		)
	}
)

export default Employee
