import { useExposure, useExposureCategories, useExposureCompanyCategories } from '../../services/exposure-service'
import { PrimaryButton } from '../../components/Footer/styleComponent/PrimaryButton'
import { useReadCompany } from '../../services/company-service'
import { useNavigate, useParams } from 'react-router-dom'
import { useUser } from '../../services/user-service'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import {
	Box,
	CircularProgress,
	CircularProgressLabel,
	Container,
	SimpleGrid,
	Text,
	VStack,
	SlideFade,
	ScaleFade,
	useMediaQuery,
	Skeleton,
	SkeletonCircle,
} from '@chakra-ui/react'
import style from '../../assets/style.json'

const Exposure = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { companyId } = useParams()
	const [isPc] = useMediaQuery('(min-width: 1300px)')

	const { data: user } = useUser()
	const { data: company, isLoading: isCompanyLoading } = useReadCompany(
		companyId != undefined ? companyId : user?.companyId
	)

	// const { data: exposure, isLoading: isExposureLoading } = useExposure(companyId || undefined)

	const {
		data: { categories },
		isLoading: isLoadingCompanyCategories,
	} = companyId ? useExposureCompanyCategories(companyId) : useExposureCategories()

	// const isLoading = isCompanyLoading || isExposureLoading || isLoadingCompanyCategories

	// console.log({ categories })

	const companyExposure = useMemo(() => {
		const categoriesArray = Object.values(categories || {})
		if (!categoriesArray) return 0
		if (categoriesArray.length === 0) return 1
		const total = categoriesArray.reduce((acc: number, category: any) => acc + category.exposure, 0)
		return total / categoriesArray.length
	}, [categories])

	return (
		<Container
			maxW='full'
			position='absolute'
			top={'90px'}
			py={20}
			centerContent
		>
			<SlideFade in>
				<Text
					color={'white'}
					dir='rtl'
					marginBottom={'77px'}
					fontWeight={500}
					fontSize={32}
				>
					{t('exposure.performance')}
				</Text>
			</SlideFade>
			<Box
				w='100%'
				maxW={style['pc-view']}
				bg='#fff'
				p={6}
				rounded='10px'
				boxShadow={'0px 0px 25px rgba(62, 62, 62, 0.08)'}
			>
				<SlideFade in>
					<Box
						w='100%'
						display={'flex'}
						flexDirection={isPc ? 'row' : 'column'}
						justifyContent='center'
						alignItems='center'
						gap={isPc ? '30px' : '10px'}
						paddingBottom='20px'
					>
						<Box
							textAlign={isPc ? 'start' : 'center'}
							paddingTop='25px'
						>
							<Text
								color={'#475154'}
								fontWeight='400'
								fontSize={22}
							>
								{company?.name}
							</Text>

							<Text
								lineHeight={isPc ? '60px' : '25px'}
								fontWeight='400'
								color={'#A9A9A9'}
								fontSize={18}
							>
								{t('exposure.generalPerformance')}
							</Text>
						</Box>
						<CircularProgress
							mt={3}
							value={Object.keys(categories || {}).length === 0 ? 0 : (companyExposure || 0) * 100}
							size='140px'
							thickness='10px'
							color={
								(companyExposure || 0) > 0.8
									? '#3FB2D2'
									: (companyExposure || 0) > 0.4
									? '#F6A860'
									: '#E55353'
							}
						>
							<CircularProgressLabel
								fontWeight='bold'
								fontSize={companyExposure === null ? 15 : 22}
								opacity={companyExposure === null ? 0.8 : 1}
							>
								{Object.keys(categories || {}).length === 0
									? t('exposure.processing') + '...'
									: (companyExposure * 100).toFixed(0) + '%'}
							</CircularProgressLabel>
						</CircularProgress>
					</Box>
				</SlideFade>

				<Text
					lineHeight={isPc ? '60px' : '18px'}
					fontWeight='400'
					fontSize={18}
					textAlign={isPc ? 'center' : 'start'}
					color='#A9A9A9'
				>
					{t('exposure.risks')}
				</Text>

				<Box
					w='100%'
					minH={240}
					py={'21px'}
				>
					{categories && !!Object.keys(categories).length ? (
						<SimpleGrid
							w='100%'
							columns={isPc ? 3 : 1}
							spacing={4}
						>
							{Object.keys(categories)?.map((id, sectionIndex) => {
								console.log({ c: categories.categories, id })
								const category = categories[id as keyof typeof categories]
								if (!category) return null

								return (
									<Box
										bg='#F5F8FA'
										key={'section-' + sectionIndex}
										w='100%'
										display={'flex'}
										justifyContent='space-between'
										padding={'20px'}
										rounded='10px'
									>
										<Box
											height={'100%'}
											display='flex'
											flexDirection={'column'}
											justifyContent='space-between'
											alignItems={'flex-start'}
										>
											<Text
												fontWeight='600'
												fontSize={18}
												opacity={1}
												color='#0E314E'
											>
												{category.name}
											</Text>
											<PrimaryButton
												onClick={() =>
													navigate(
														companyId
															? `/admin/exposure/${id}/${companyId}`
															: `/exposure/${id}`
													)
												}
											>
												{t('exposure.check')}
											</PrimaryButton>
										</Box>

										<ScaleFade
											in
											initialScale={0}
										>
											<VStack
												w='100%'
												spacing={1}
											>
												<CircularProgress
													value={(category.exposure || 0) * 100}
													size='100px'
													thickness='10px'
													color={
														category.exposure > 0.8
															? '#3FB2D2'
															: category.exposure > 0.4
															? '#F6A860'
															: '#E55353'
													}
												>
													<CircularProgressLabel
														fontWeight='bold'
														fontSize={19}
													>
														{((category.exposure || 0) * 100).toFixed(0)}%
													</CircularProgressLabel>
												</CircularProgress>
											</VStack>
										</ScaleFade>
									</Box>
								)
							})}
						</SimpleGrid>
					) : (
						<Loading isPc={isPc} />
					)}
				</Box>
			</Box>
		</Container>
	)
}

const Loading = ({ isPc }: { isPc: boolean }) => (
	<SimpleGrid
		w='100%'
		columns={isPc ? 3 : 1}
		spacing={4}
	>
		{[...Array(3)].map((_, index) => (
			<Box
				key={index}
				bg='#F5F8FA'
				w='100%'
				display={'flex'}
				justifyContent='space-between'
				padding={'20px'}
				rounded='10px'
			>
				<Box
					height={'100%'}
					display='flex'
					flexDirection={'column'}
					justifyContent='space-between'
					alignItems={'flex-start'}
				>
					<Skeleton
						height='20px'
						width='60%'
						mb='12px'
					/>
					<Skeleton
						height='36px'
						width='200px'
						borderRadius={'3xl'}
					/>
				</Box>

				<VStack
					w='100%'
					spacing={1}
					justifyContent={'end'}
					alignItems={'end'}
				>
					<SkeletonCircle size='100' />
				</VStack>
			</Box>
		))}
	</SimpleGrid>
)

export default Exposure
