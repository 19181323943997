import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { appConfig } from '../../configs/app'
import exportFromJSON from 'export-from-json'
import axios from 'axios'

const Logger = () => {
	const params = useParams()

	const getLogs = async (daysAgo: string) => {
		let response = await axios.get(`${appConfig.apiUrl}/log/${daysAgo}?token=${localStorage.getItem('token')}`)
		console.log(response)

		const data = response.data.map((item: any) => {
			return {
				Date: item.date,
				Content: item.content,
			}
		})
		const fileName = `logs-${daysAgo}-day-ago-${new Date().toDateString()}`
		const exportType = 'xls'
		exportFromJSON({ data, fileName, exportType })
	}

	useEffect(() => {
		console.log(params)
		const { daysAgo } = params
		if (daysAgo) {
			getLogs(daysAgo)
		}
	}, [])

	return null
}
export default Logger
