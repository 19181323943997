import { ReportsController } from '../types/server/endpoints/reports'
import { appConfig } from '../configs/app'
import fetcher from '../utils/fetcher'
import axios from 'axios'
import useSWR from 'swr'

export const useListCompanyReports = (companyId?: string) => {
	const fetch = useSWR<ReportsController['list']['returns']>(
		companyId ? `${appConfig.apiUrl}/reports/${companyId}` : undefined,
		fetcher
	)

	return {
		...fetch,
		data: fetch.data,
	}
}

class ReportsService {
	static async list(companyId: string) {
		try {
			const response = await axios.get(`${appConfig.apiUrl}/reports/${companyId}`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})

			return response.data as ReportsController['list']['returns']
		} catch (error) {
			return null
		}
	}

	static async current(companyId: string) {
		try {
			const response = await axios.get(`${appConfig.apiUrl}/reports/current/${companyId}`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})
			return response.data
		} catch (error) {
			return null
		}
	}

	static async getForEmployee(employeeId: string) {
		try {
			const response = await axios.get(`${appConfig.apiUrl}/reports/user/${employeeId}`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})
			return response.data
		} catch (error) {
			return null
		}
	}
}

export default ReportsService
