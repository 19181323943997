import newslatterService, { useListNewslatter } from '../../services/newslatter-service'
import ImageViewer from 'react-simple-image-viewer'
import style from '../../assets/style.json'
import Swal from 'sweetalert2'
import { LanguageContext } from '../../locales/LocaleContext'
import { CirclePlusIcon } from '../../assets/CirclePlusIcon'
import { ArrowDownIcon } from '../../assets/ArrowDownIcon'
import { useUser } from '../../services/user-service'
import { getDirection } from '../../locales/locale'
import { TrashIcon } from '../../assets/TrashIcon'
import { EditIcon2 } from '../../assets/EditIcon2'
import { MenuIcon } from '../../assets/MenuIcon'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { appConfig } from '../../configs/app'
import { useContext, useState } from 'react'
import {
	Box,
	Button,
	Container,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useMediaQuery,
	Flex,
	Skeleton,
} from '@chakra-ui/react'

const Newslattter = () => {
	const { t } = useTranslation()
	const [goingDeleteId, setGoingDeleteId] = useState<string>()
	const [currentImage, setCurrentImage] = useState(0)
	const [isViewerOpen, setIsViewerOpen] = useState(false)
	const [isPc] = useMediaQuery('(min-width: 1300px)')
	const navigate = useNavigate()

	const { locale } = useContext(LanguageContext)
	const dir = getDirection(locale)

	const getImageLink = (filePath: string) => {
		return `${appConfig.apiUrl}/file?path=${filePath}&token=${localStorage.getItem('token')}`
	}

	const { user } = useUser()
	const isAdmin = user?.companyId === '123'

	// const openImageViewer = useCallback((index) => {
	//   console.log(index);
	//   console.log(images[index]);

	//   setCurrentImage(index);
	//   setIsViewerOpen(true);
	// }, []);

	const closeImageViewer = () => {
		setCurrentImage(0)
		setIsViewerOpen(false)
	}

	const remove = async (id: string) => {
		const result = await Swal.fire({
			title: t('newsletter.deleteNewsletter') + '?',
			text: t('newsletter.deleteWarning'),
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			cancelButtonText: t('newsletter.cancel'),
			confirmButtonText: t('newsletter.delete'),
		})

		if (result.isConfirmed) {
			setGoingDeleteId(id)
			await newslatterService.delete(id)
			await mutate()
			setGoingDeleteId('')
		}
	}

	const { data: newslatter, mutate, isLoading } = useListNewslatter()
	const reverseNewslatter = newslatter.toReversed()

	const images = reverseNewslatter.map((post) => {
		return post.filePath ? getImageLink(post.filePath) : ''
	})

	return (
		<Container
			position='absolute'
			top={'90px'}
			maxW='full'
			py={20}
			centerContent
		>
			{isLoading || !user ? (
				<>
					<Flex justify='center'>
						<Skeleton
							height='30px'
							width='200px'
							borderRadius='md'
						/>
					</Flex>
				</>
			) : (
				<>
					<Text
						color={'white'}
						dir='ltr'
						fontWeight={500}
						fontSize={22}
					>
						{t('newsletter.hello') + ' ' + user.name}
					</Text>
					<Text
						color={'white'}
						dir='rtl'
						marginBottom={'0px'}
						fontWeight={500}
						fontSize={22}
					>
						{t('newsletter.peaceOfMind')}
					</Text>

					<>
						{isViewerOpen && (
							<ImageViewer
								src={images}
								currentIndex={currentImage}
								disableScroll={false}
								closeOnClickOutside={true}
								onClose={closeImageViewer}
							/>
						)}
					</>
				</>
			)}

			<Box
				rounded={'10px'}
				boxShadow='0px 0px 25px rgba(62, 62, 62, 0.08)'
				mt={10}
				maxW={style['pc-view']}
				w='100%'
				paddingInline={'28px'}
				bg={'white'}
				minHeight='380px'
			>
				<Box
					display={'flex'}
					flexDirection='row-reverse'
					width={'100%'}
				>
					{isLoading ? (
						<>
							<Skeleton
								height='40px'
								width='200px'
								borderRadius='3xl'
								marginBlock={'28px'}
							/>{' '}
						</>
					) : (
						<>
							{isAdmin && (
								<Button
									size='sm'
									colorScheme='blue'
									onClick={() => navigate('/admin/modify-newslatter')}
									marginBlock='28px'
									width={'188px'}
									gap='8px'
									height='44px'
									rounded={'22px'}
									bg={'white'}
									color='#3FB2D2'
									border={'1px solid #3FB2D2'}
								>
									<Text
										fontSize={'18px'}
										fontWeight='600'
									>
										{t('newsletter.addTraining')}
									</Text>
									<CirclePlusIcon />
								</Button>
							)}
						</>
					)}
				</Box>
				{isLoading ? (
					<>
						{[...Array(3)].map((_, index) => (
							<Box
								key={index}
								display='flex'
								justifyContent='space-between'
								paddingBlock={'30px'}
								borderBottom={'1.5px solid #F0EEEE'}
							>
								<Skeleton
									height='40px'
									width='85%'
									borderRadius='md'
								/>
								<Box
									display='flex'
									alignItems='center'
								>
									<Skeleton
										height='40px'
										width='40px'
										borderRadius='md'
									/>
									<Skeleton
										height='40px'
										width='40px'
										borderRadius='md'
										ml={2}
									/>
									<Skeleton
										height='40px'
										width='40px'
										borderRadius='md'
										ml={2}
									/>
								</Box>
							</Box>
						))}
					</>
				) : reverseNewslatter.length ? (
					reverseNewslatter.map((newslatterItem, index) => (
						<Box
							key={index}
							_focus={{ outline: 0 }}
							display='flex'
							justifyContent='space-between'
							paddingBlock={'30px'}
							borderBottom={'1.5px solid #F0EEEE'}
							cursor='pointer'
							onClick={() => {
								navigate(`/view-newslatter/${newslatterItem.id}`)
							}}
						>
							<Text
								wordBreak='break-word'
								fontWeight='bold'
								fontSize={22}
								textAlign='start'
							>
								{newslatterItem.title}
							</Text>
							<Box
								display={'flex'}
								alignItems='center'
							>
								{isAdmin && isPc && (
									<Box width={'130px'}>
										<Button
											disabled={goingDeleteId === newslatterItem.id}
											title={t('newsletter.edit')}
											color={'#3FB2D2'}
											bg={'transparent'}
											onClick={(ev) => {
												ev.stopPropagation()
												navigate(`/admin/modify-newslatter/${newslatterItem?.id}`)
											}}
										>
											<EditIcon2 />
										</Button>
										<Button
											disabled={goingDeleteId === newslatterItem.id}
											title={t('newsletter.delete')}
											color={'#3FB2D2'}
											bg={'transparent'}
											onClick={(ev) => {
												ev.stopPropagation()
												remove(newslatterItem.id)
											}}
										>
											<TrashIcon />
										</Button>
									</Box>
								)}
								{isAdmin && !isPc && (
									<Box
										onClick={(ev) => {
											ev.stopPropagation()
										}}
									>
										<Menu>
											<MenuButton
												bg='transparent'
												as={Button}
											>
												<MenuIcon />
											</MenuButton>
											<MenuList
												overflowY='auto'
												maxH={'300px'}
												maxW={'150px'}
											>
												<MenuItem
													disabled={goingDeleteId === newslatterItem.id}
													color='#3FB2D2'
													display='flex'
													justifyContent={'space-between'}
													onClick={(ev) => {
														ev.stopPropagation()
														navigate(`/admin/modify-newslatter/${newslatterItem?.id}`)
													}}
												>
													{t('newsletter.edit')}
													<EditIcon2 />
												</MenuItem>
												<MenuItem
													display='flex'
													justifyContent={'space-between'}
													color='#3FB2D2'
													disabled={goingDeleteId === newslatterItem.id}
													onClick={(ev) => {
														ev.stopPropagation()
														remove(newslatterItem.id)
													}}
												>
													{t('newsletter.delete')}
													<TrashIcon />
												</MenuItem>
											</MenuList>
										</Menu>
									</Box>
								)}
								<Box
									transformOrigin={'center'}
									transform={dir === 'rtl' ? 'rotate(90deg)' : 'rotate(-90deg)'}
								>
									<ArrowDownIcon />
								</Box>
							</Box>
						</Box>
					))
				) : (
					<Text
						color={'#3FB2D2'}
						fontSize={22}
						fontWeight={500}
						textAlign='center'
						width='100%'
					>
						{t('newsletter.noTraining')}
					</Text>
				)}
			</Box>
		</Container>
	)
}

export default Newslattter
