import type { EmployeeExposure, ExposureCategoryExtended } from '../../types/server/generics/exposure'
import type { Role } from '../../types/server/generics/role'
import { CircularProgress, Text, ScaleFade, HStack, Tr, Td } from '@chakra-ui/react'
import { useListTasklist } from '../../services/tasklist-service'
import { Link, useParams } from 'react-router-dom'
import { isHaveRoleIdInParents } from '.'

const Employee = ({
	index,
	id,
	role,
	data,
	...baseEmployee
}: EmployeeExposure & { index: number; role: Role | null; data: ExposureCategoryExtended | null }) => {
	const { data: employeeTaskList } = useListTasklist(id)
	const { companyId } = useParams()

	const roleToTaskMap = employeeTaskList.reduce((acc, Tasklist) => {
		if (companyId) {
			acc[Tasklist.roleId] = Tasklist.id
		} else {
			console.log('Tasklist', role)
			const taskRole = data?.company?.roles.find((mapRole) => mapRole.id === Tasklist.roleId)

			const parentIds = taskRole?.parentIds || []

			if (
				role &&
				isHaveRoleIdInParents(role.id, parentIds, data?.company?.roles || []) &&
				Tasklist.list?.some((task: any) => task.categoryId === id)
			) {
				acc[Tasklist.roleId] = Tasklist.id
				console.log('employeeTaskList', Tasklist.id, acc)
			}
		}

		return acc
	}, {} as Record<string, string>)

	const employee = {
		...baseEmployee,
		...(data?.company?.employees?.filter((x) => x.id === id)?.[0] || {}),
	}

	const exposure = employee.sum > 0 ? (employee.exposure || 0) * 100 : 100
	

	const roles = data?.company?.roles?.filter((role) => {
		return !(!roleToTaskMap || !roleToTaskMap[role.id] || !employee.id)
	})

	if (!employee.isActive) return null
	if (!employee.sum) return null

	return (
		<Tr
			rounded={'10px'}
			border={'none'}
			key={'section-' + index}
			pos={'relative'}
		>
			<Td
				bg={index % 2 === 0 ? 'white' : '#F5F8FA'}
				border={'none'}
				roundedRight='10px'
				textAlign='start'
			>
				<Text
					flex={1}
					whiteSpace='nowrap'
					px={2}
					fontSize={15}
					fontWeight='bold'
					color='#3FB2D2'
				>
					{roles && roles.length > 0 ? (
						<span>
							{roles.map((role: any, idx: number) => {
								const isLast = idx === roles.length - 1
								return (
									<>
										<Link
											className={
												!roleToTaskMap || !roleToTaskMap[role.id] || !employee.id
													? 'disabled'
													: ''
											}
											to={`/tasks/${roleToTaskMap ? roleToTaskMap[role.id] : ''}?user_id=${
												employee.id
											}`}
										>
											{employee.name}
										</Link>
										<span>{isLast ? '' : ', '}</span>
									</>
								)
							})}
						</span>
					) : (
						employee.name
					)}
				</Text>
			</Td>

			<Td
				display='flex'
				alignItems='flex-start'
				justifyContent='flex-start'
				textAlign='center'
				border={'none'}
				bg={index % 2 === 0 ? 'white' : '#F5F8FA'}
				roundedLeft={'10px'}
			>
				<ScaleFade
					in
					initialScale={0}
				>
					<HStack spacing={1}>
						<Text
							textAlign={'end'}
							width={'50px'}
						>
							{exposure.toFixed(0)}%
						</Text>
						<CircularProgress
							value={exposure}
							size='40px'
							thickness='10px'
							color={
								exposure < 25
									? 'red.500'
									: exposure < 50
									? 'yellow.300'
									: exposure < 85
									? '#F6A860'
									: '#3FB2D2'
							}
						/>
					</HStack>
				</ScaleFade>
			</Td>

			<Td
				bg={index % 2 === 0 ? 'white' : '#F5F8FA'}
				border={'none'}
				textAlign='start'
			>
				<Text
					px={5}
					fontSize={15}
					textAlign='start'
					gap='5'
					alignItems={'flex-start'}
					justifyContent='center'
					whiteSpace='nowrap'
					width={'100%'}
				>
					{roles && roles.length > 0 ? (
						<span>
							{roles.map((role: any, idx: number) => (
								<>
									{role.name}
									<span>{idx < roles.length - 1 ? ', ' : ''}</span>
								</>
							))}
						</span>
					) : (
						'--'
					)}
				</Text>
			</Td>
		</Tr>
	)
}

export default Employee
