import type { Company } from '../../types/server/generics/company'
import GuidingQuestionnaireService, {
	GuidingQuestionnaire,
	Question,
} from '../../services/guiding-questionnaire-service'
import { ArrowDownIcon } from '../../assets/ArrowDownIcon'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import CompanyService, { useListCompanies } from '../../services/company-service'
import style from '../../assets/style.json'

import {
	Box,
	Button,
	Checkbox,
	Container,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	SlideFade,
	Text,
	useMediaQuery,
} from '@chakra-ui/react'

function ModifyGuidingQuestionnaire() {
	const { t } = useTranslation()
	const [submitting, setSubmitting] = useState<boolean>(false)
	const [isPc] = useMediaQuery('(min-width: 1300px)')

	const { data: companies } = useListCompanies()

	const [questionnaire, setQuestionnaire] = useState<
		Omit<
			GuidingQuestionnaire,
			'_id' | 'id' | 'questions' | 'companyIds' | 'roleIds' | 'createdAt' | 'updatedAt' | 'users'
		>
	>({
		name: '',
	})

	const [selectedCompaniesIds, setSelectedCompaniesIds] = useState<string[]>([])

	const [selectedRolesIds, setSelectedRolesIds] = useState<string[]>([])

	const isShouldSelectRoles = selectedCompaniesIds.length === 1
	const selectedCompany = isShouldSelectRoles ? companies.find((i) => i.id === selectedCompaniesIds[0]) : undefined
	const selectedCompanyRoles = selectedCompany ? selectedCompany.roles : []

	const navigate = useNavigate()
	const { id } = useParams()

	const [questions, setQuestions] = useState<Omit<Question, 'id'>[]>([])

	const onAddQuestion = () => {
		setQuestions([
			...questions,
			{
				content: '',
				options: [],
				correctOptionIndexs: [],
			},
		])
	}

	const updateQuestionByIndex = (index: number, question: Omit<Question, 'id'>) => {
		const newQuestions = [...questions]
		newQuestions[index] = question
		setQuestions(newQuestions)
	}

	const removeQuestionByIndex = (index: number) => {
		setQuestions(questions.filter((_, i) => i !== index))
	}

	useEffect(() => {
		updateItem()
	}, [])

	const isNameValid = questionnaire.name.trim().length > 1
	const isAtleastOneQuestion = questions.length > 0
	const isAllQuestionsValid = questions.every(
		(question) =>
			question.content.trim().length > 1 &&
			question.options.length > 1 &&
			question.correctOptionIndexs.length > 0 &&
			question.options.every((i) => i.length > 1)
	)

	const isValid = isNameValid && isAtleastOneQuestion && isAllQuestionsValid

	console.log({
		isNotValid: !isValid,
		isNameValid,
		isAtleastOneQuestion,
		isAllQuestionsValid,
		submitting,
	})

	const submit = async () => {
		if (!isValid) return

		setSubmitting(true)

		await GuidingQuestionnaireService.modify({
			...questionnaire,
			companyIds: selectedCompaniesIds,
			questions,
			roleIds: selectedRolesIds,
		})

		setSubmitting(false)
		navigate('/guiding-questionnaire')
	}

	const updateItem = async () => {
		if (id) {
			const selectedQuestionnaire = await GuidingQuestionnaireService.read(id)
			setQuestionnaire(selectedQuestionnaire)
			setSelectedCompaniesIds(selectedQuestionnaire.companyIds ?? [])
			setQuestions(selectedQuestionnaire.questions)
			setSelectedRolesIds(selectedQuestionnaire.roleIds ?? [])
		}
	}


	return (
		<Container
			maxW='full'
			minH='100vh'
			position={'absolute'}
			top={'90px'}
			py={20}
			centerContent
		>
			<SlideFade in>
				<Text
					color={'white'}
					dir='rtl'
					marginBottom={'77px'}
					fontWeight={500}
					fontSize={32}
				>
					{t('modifyGuidingQuestionnaire.addQuestionnaire')}
				</Text>
			</SlideFade>

			<Box
				padding={'21px'}
				maxW={style['pc-view']}
				w='100%'
				bg='#fff'
				boxShadow={'0px 0px 25px rgba(62, 62, 62, 0.08)'}
				rounded='10px'
				display={'flex'}
				flexDirection={'column'}
				gap='20px'
			>
				<Box
					display={'flex'}
					flexDirection='column'
					gap={'21px'}
					rounded={'10px'}
					padding={'21px'}
					bg='#F5F8FA'
				>
					<Text
						fontSize={'22px'}
						fontWeight='600'
						color={'#0E314E'}
						lineHeight='30px'
					>
						{t('modifyGuidingQuestionnaire.generalDetails')}
					</Text>
					<Box>
						<Text
							fontSize={'14px'}
							fontWeight='400'
							color={'#aaa'}
							lineHeight='19px'
							pb={'5px'}
						>
							{t('modifyGuidingQuestionnaire.title')}
						</Text>
						<Input
							bg={'#fff'}
							border='1.2521px solid #E2E2E2'
							fontSize={'18px'}
							fontWeight='400'
							maxLength={500}
							placeholder={t('modifyGuidingQuestionnaire.titleDescription')}
							isRequired
							value={questionnaire.name}
							onChange={(e) => setQuestionnaire({ ...questionnaire, name: e.target.value })}
						/>
					</Box>
				</Box>

				<Box
					display={'flex'}
					flexDirection='column'
					gap={'21px'}
					rounded={'10px'}
					padding={'21px'}
					bg='#F5F8FA'
				>
					<Text
						fontSize={'22px'}
						fontWeight='600'
						color={'#0E314E'}
						lineHeight='30px'
					>
						{t('modifyGuidingQuestionnaire.recipients')}
					</Text>
					<Box>
						<Text
							fontSize={'14px'}
							fontWeight='400'
							color={'#aaa'}
							lineHeight='19px'
							pb={'5px'}
						>
							{t('modifyGuidingQuestionnaire.recipients')}
						</Text>
						<Box position={'relative'}>
							<Menu closeOnSelect={false}>
								<MenuButton as={Box}>
									<Box
										bg={'#fff'}
										border='1.2521px solid #E2E2E2'
										fontSize={'18px'}
										fontWeight='400'
										width='100%'
										rounded={'7px'}
										display='flex'
										alignItems='center'
										justifyContent={'space-between'}
										flexDirection='row'
										paddingBlock='9px'
										paddingInline='12px'
									>
										{selectedCompaniesIds.length > 0 ? (
											<Text
												fontSize={'18px'}
												fontWeight='400'
												color={'#aaa'}
											>
												(
												{`${t('modifyGuidingQuestionnaire.recipients')} ${
													selectedCompaniesIds.length
												}`}
												)
											</Text>
										) : (
											<Text
												fontSize={'18px'}
												fontWeight='400'
												color={'#aaa'}
											>
												({t('modifyGuidingQuestionnaire.companyName')})
											</Text>
										)}
										<ArrowDownIcon />
									</Box>
								</MenuButton>
								<div className='companiesParent'>
									<MenuList
										width={'100%'}
										maxHeight={300}
										overflow={'scroll'}
									>
										<MenuItem>
											<Button
												variant='link'
												color='blue.400'
												onClick={() => {
													setSelectedCompaniesIds(
														selectedCompaniesIds.length === companies.length
															? []
															: companies.map((i) => i.id)
													)
												}}
											>
												{t('modifyGuidingQuestionnaire.selectAll')}
											</Button>
										</MenuItem>

										{companies.map((company) => {
											return (
												<MenuItem key={company.id}>
													<Checkbox
														size='lg'
														colorScheme='white'
														borderColor='#3FB2D2'
														iconColor='#3FB2D2'
														iconSize={'17px'}
														isChecked={selectedCompaniesIds.includes(company.id)}
														onChange={() => {
															setSelectedCompaniesIds(
																selectedCompaniesIds.includes(company.id)
																	? selectedCompaniesIds.filter(
																			(i) => i !== company.id
																	  )
																	: [...selectedCompaniesIds, company.id]
															)
														}}
														className='!text-wrap'
													>
														{company.name}
													</Checkbox>
												</MenuItem>
											)
										})}
									</MenuList>
								</div>
							</Menu>
						</Box>

						{isShouldSelectRoles && selectedCompanyRoles && (
							<Box>
								<Text
									fontSize={'14px'}
									fontWeight='400'
									color={'#aaa'}
									lineHeight='19px'
									marginTop={'21px'}
									pb={'5px'}
								>
									{t('modifyGuidingQuestionnaire.recipientsRoles')}
								</Text>
								<Box position={'relative'}>
									<Menu closeOnSelect={false}>
										<MenuButton as={Box}>
											<Box
												bg={'#fff'}
												border='1.2521px solid #E2E2E2'
												fontSize={'18px'}
												fontWeight='400'
												width='100%'
												rounded={'7px'}
												display='flex'
												alignItems='center'
												justifyContent={'space-between'}
												flexDirection='row'
												paddingBlock='9px'
												paddingInline='12px'
											>
												{selectedCompaniesIds.length > 0 ? (
													<Text
														fontSize={'18px'}
														fontWeight='400'
														color={'#aaa'}
													>
														(
														{`${t('modifyGuidingQuestionnaire.recipientsRoles')} ${
															selectedRolesIds.length
														}`}
														)
													</Text>
												) : (
													<Text
														fontSize={'18px'}
														fontWeight='400'
														color={'#aaa'}
													>
														({t('modifyGuidingQuestionnaire.recipientsRoles')})
													</Text>
												)}
												<ArrowDownIcon />
											</Box>
										</MenuButton>
										<div className='companiesParent'>
											<MenuList
												width={'100%'}
												maxHeight={300}
												overflow={'scroll'}
											>
												<MenuItem>
													<Button
														variant='link'
														color='blue.400'
														onClick={() => {
															setSelectedRolesIds(
																selectedRolesIds.length === selectedCompanyRoles.length
																	? []
																	: selectedCompanyRoles.map((i) => i.id)
															)
														}}
													>
														{t('modifyGuidingQuestionnaire.selectAll')}
													</Button>
												</MenuItem>

												{selectedCompanyRoles.map((role) => {
													return (
														<MenuItem key={role.id}>
															<Checkbox
																size='lg'
																colorScheme='white'
																borderColor='#3FB2D2'
																iconColor='#3FB2D2'
																iconSize={'17px'}
																isChecked={selectedRolesIds.includes(role.id)}
																onChange={() => {
																	setSelectedRolesIds(
																		selectedRolesIds.includes(role.id)
																			? selectedRolesIds.filter(
																					(i) => i !== role.id
																			  )
																			: [...selectedRolesIds, role.id]
																	)
																}}
															>
																{role.name}
															</Checkbox>
														</MenuItem>
													)
												})}
											</MenuList>
										</div>
									</Menu>
								</Box>
							</Box>
						)}
					</Box>
				</Box>

				{/* QUESTIONS */}
				{questions.map((question, index) => {
					const updateQuestion = (question: Omit<Question, 'id'>) => {
						updateQuestionByIndex(index, question)
					}

					return (
						<Box
							display={'flex'}
							flexDirection='column'
							gap={'21px'}
							rounded={'10px'}
							padding={'21px'}
							bg='#F5F8FA'
							key={index}
						>
							<Box
								display='flex'
								justifyContent='space-between'
								alignItems='center'
							>
								<Text
									fontSize={'22px'}
									fontWeight='600'
									color={'#0E314E'}
									lineHeight='30px'
								>
									{t('modifyGuidingQuestionnaire.question')} {index + 1}
								</Text>
								<Button onClick={removeQuestionByIndex.bind(null, index)}>
									{t('modifyGuidingQuestionnaire.deleteQuestion')}
								</Button>
							</Box>
							<Box>
								<Text
									fontSize={'14px'}
									fontWeight='400'
									color={'#aaa'}
									lineHeight='19px'
									pb={'5px'}
								>
									{t('modifyGuidingQuestionnaire.questionContent')}
								</Text>
								<Input
									bg={'#fff'}
									border='1.2521px solid #E2E2E2'
									fontSize={'18px'}
									fontWeight='400'
									maxLength={2560}
									placeholder={t('modifyGuidingQuestionnaire.questionContent')}
									isRequired
									value={question.content}
									onChange={(e) => {
										updateQuestion({
											...question,
											content: e.target.value,
										})
									}}
								/>
								<Box
									display={'flex'}
									flexDirection='column'
									gap={2}
									pt={4}
								>
									<Text
										fontSize={'14px'}
										fontWeight='400'
										color={'#aaa'}
										lineHeight='19px'
										pb={'5px'}
									>
										{t('modifyGuidingQuestionnaire.options')}
									</Text>
									{question.options.map((option, optionIndex: number) => {
										return (
											<Box
												display='flex'
												gap={2}
												key={optionIndex}
												className='lg:flex-row flex-col mb-4 lg:mb-0'
											>
												<Input
													bg={'#fff'}
													border='1.2521px solid #E2E2E2'
													fontSize={'18px'}
													fontWeight='400'
													maxLength={500}
													placeholder={`${t('modifyGuidingQuestionnaire.option')} ${
														optionIndex + 1
													}`}
													isRequired
													value={option}
													onChange={(e) => {
														const newOptions = [...question.options]

														newOptions[optionIndex] = e.target.value

														updateQuestion({
															...question,
															options: newOptions,
														})
													}}
												/>
												<Button
													onClick={() => {
														updateQuestion({
															...question,
															correctOptionIndexs:
																// question.correctOptionIndexs.includes(
																// 	optionIndex,
																// )
																// 	? question.correctOptionIndexs.filter(
																// 			(i) => i !== optionIndex,
																// 		)
																// 	: [
																// 			...question.correctOptionIndexs,
																// 			optionIndex,
																// 		],
																question.correctOptionIndexs.includes(optionIndex)
																	? []
																	: [optionIndex],
														})
													}}
													colorScheme={
														question.correctOptionIndexs.includes(optionIndex)
															? 'teal'
															: 'gray'
													}
												>
													{question.correctOptionIndexs.includes(optionIndex)
														? t('modifyGuidingQuestionnaire.isCorrect')
														: t('modifyGuidingQuestionnaire.isNotCorrect')}
												</Button>
												<Button
													onClick={() => {
														updateQuestion({
															...question,
															options: question.options.filter(
																(_, i: number) => i !== optionIndex
															),
														})
													}}
												>
													{t('modifyGuidingQuestionnaire.deleteOption')}
												</Button>
											</Box>
										)
									})}
									<Button
										onClick={() => {
											updateQuestion({
												...question,
												options: [...question.options, ''],
											})
										}}
										className='mt-6'
									>
										{t('modifyGuidingQuestionnaire.addOption')}
									</Button>
								</Box>
							</Box>
						</Box>
					)
				})}

				<Button onClick={onAddQuestion}>{t('modifyGuidingQuestionnaire.addQuestion')}</Button>
				{/* QUESTIONS */}

				<Box
					display={'flex'}
					flexDirection='row-reverse'
				>
					<Button
						color={'white'}
						minW={isPc ? '200px' : 'full'}
						height='60px'
						textAlign='center'
						border='3px solid #FFFFFF'
						background={'#3FB2D2'}
						fontSize='18px'
						fontWeight={700}
						rounded={'100px'}
						filter='drop-shadow(0px 4px 30px rgba(63, 178, 210, 0.44))'
						onClick={submit}
						disabled={!isValid || submitting}
						isLoading={submitting}
					>
						{t('modifyGuidingQuestionnaire.save')}
					</Button>
				</Box>
			</Box>
		</Container>
	)
}

export default ModifyGuidingQuestionnaire
