import axios from 'axios'

/*
const response = await axios.get(`${appConfig.apiUrl}/exposure`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        })
*/

const fetcher = (url: string) =>
	axios
		.get(url, {
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		})
		.then((res) => res.data)

export default fetcher
