class UtilSrvice {
	static isEmailValid(email: string) {
		return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
	}

	static dateLocalStringToInputFormat(dateString: string) {
		const onlyDate = dateString.split(',')[0]
		const [day, month, year] = onlyDate.split('/')
		return `${year}-${month}-${day}`
	}

	static isoSrtingToDDMMYYY_HHMM(isoString: string) {
		const [date, time] = isoString.split('T')
		const [year, month, day] = date.split('-')
		const [hour, minute] = time.split(':')
		return `${day}/${month}/${year} ${hour}:${minute}`
	}

	static timestampToDDMMYYYY(timestamp: number) {
		const date = new Date(timestamp)
		const year = date.getFullYear()
		const month = date.getMonth() + 1
		const day = date.getDate()
		return this._addZeroIfNeed(day) + '/' + this._addZeroIfNeed(month) + '/' + year
	}

	static _addZeroIfNeed(number: number) {
		return number < 10 ? '0' + number : '' + number
	}

	static genId() {
		return Math.random().toString(36).substr(2, 9)
	}
}

export default UtilSrvice
