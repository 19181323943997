import type { CompanyWithRoles } from '../../types/server/generics/company'
import { Box, Button, Container, Input, Textarea, Checkbox, SlideFade, Text, useMediaQuery } from '@chakra-ui/react'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { useListCompanies } from '../../services/company-service'
import { LanguageContext } from '../../locales/LocaleContext'
import { ArrowDownIcon } from '../../assets/ArrowDownIcon'
import { useNavigate, useParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { getDirection } from '../../locales/locale'
import { Switch } from '../../components/Switch'
import { useTranslation } from 'react-i18next'
import newslatterService, { useListNewslatter, useReadNewslatter } from '../../services/newslatter-service'
import style from '../../assets/style.json'
import Swal from 'sweetalert2'

// const FileSaver = require('file-saver')
// const mime = require('mime');

const ModifyNewslattter = () => {
	const { t } = useTranslation()
	const [submitting, setSubmitting] = useState<boolean>(false)
	const [isPc] = useMediaQuery('(min-width: 1300px)')
	const [newslatter, setNewslatter] = useState<any>({
		title: '',
		content: '',
		link: '',
		file: null,
	})

	const isValid = newslatter.title.trim().length > 1 && newslatter.content.trim().length > 1

	const [selectedCompaniesIds, setSelectedCompaniesIds] = useState<string[]>([])
	const [selectedRolesIds, setSelectedRolesIds] = useState<string[]>([])

	const { data: companies } = useListCompanies() as {
		data: CompanyWithRoles[]
	}

	const { locale } = useContext(LanguageContext)
	const dir = getDirection(locale)

	const isShouldSelectRoles = selectedCompaniesIds.length === 1
	const selectedCompany: CompanyWithRoles | undefined = isShouldSelectRoles
		? companies.find((i) => i.id === selectedCompaniesIds[0])
		: undefined

	const selectedCompanyRoles = selectedCompany ? selectedCompany.roles : []

	const navigate = useNavigate()
	const { id } = useParams()

	const { data: newsletter } = useReadNewslatter(id)
	const { mutate } = useListNewslatter()

	useEffect(() => {
		if (newsletter) {
			setNewslatter(newsletter)
			setSelectedCompaniesIds(newsletter.companies ?? [])
			setSelectedRolesIds(newsletter.roleIds ?? [])
		}
	}, [newsletter])

	const [file, setFile] = useState<any | null>(null)

	const submit = async () => {
		if (!isValid) {
			return Swal.fire({
				icon: 'error',
				title: t('modifyNewsletter.error'),
				text: t('modifyNewsletter.errorDescription'),
			})
		}

		setSubmitting(true)

		await newslatterService.modifyFormData(
			{ ...newslatter, isShowVideo: !!newslatter.isShowVideo },
			file,
			newslatter.filePath,
			selectedCompaniesIds,
			selectedRolesIds
		)

		await mutate()

		setSubmitting(false)
		navigate('/newslatter')
	}

	const uploadFile = async () => {
		if (file?.name || newslatter.filePath) {
			const result = await Swal.fire({
				title: t('modifyNewsletter.deleteFile') + (file?.name || newslatter.fileName) + '?',
				text: t('modifyNewsletter.deleteWarning'),
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: t('modifyNewsletter.cancel'),
				confirmButtonText: t('modifyNewsletter.delete'),
			})

			if (result.isConfirmed) {
				setFile(null)
				setNewslatter({ ...newslatter, filePath: undefined })
			}

			return
		}

		const input = document.getElementById('fileInput')
		input?.click()
	}

	const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		// @ts-ignore
		const file = e.target!.files[0]
		if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
		} else {
			setFile(file)
		}
	}

	return (
		<Container
			maxW='full'
			minH='100vh'
			position={'absolute'}
			top={'90px'}
			py={20}
			centerContent
		>
			<SlideFade in>
				<Text
					color={'white'}
					dir='rtl'
					marginBottom={'77px'}
					fontWeight={500}
					fontSize={32}
				>
					{t('modifyNewsletter.addTraining')}
				</Text>
			</SlideFade>

			<input
				type='file'
				id='fileInput'
				style={{ visibility: 'hidden', width: 0, height: 0 }}
				onChange={(e) => handleFileInput(e)}
				// allow only images
				accept='image/*'
			></input>
			<Box
				padding={'21px'}
				maxW={style['pc-view']}
				w='100%'
				bg='#fff'
				boxShadow={'0px 0px 25px rgba(62, 62, 62, 0.08)'}
				rounded='10px'
				display={'flex'}
				flexDirection={'column'}
				gap='20px'
			>
				<Box
					display={'flex'}
					flexDirection='column'
					gap={'21px'}
					rounded={'10px'}
					padding={'21px'}
					bg='#F5F8FA'
				>
					<Text
						fontSize={'22px'}
						fontWeight='600'
						color={'#0E314E'}
						lineHeight='30px'
					>
						{t('modifyNewsletter.generalDetails')}
					</Text>
					<Box>
						<Text
							fontSize={'14px'}
							fontWeight='400'
							color={'#aaa'}
							lineHeight='19px'
							pb={'5px'}
						>
							{t('modifyNewsletter.title')}
						</Text>
						<Input
							bg={'#fff'}
							border='1.2521px solid #E2E2E2'
							fontSize={'18px'}
							fontWeight='400'
							maxLength={128}
							placeholder={t('modifyNewsletter.titleDescription')}
							isRequired
							value={newslatter.title}
							onChange={(e) => setNewslatter({ ...newslatter, title: e.target.value })}
						/>
					</Box>

					<Box
						display={'flex'}
						gap='16px'
						alignItems='center'
						flexDir={dir === 'rtl' ? 'row-reverse' : 'row'}
						justifyContent={dir === 'rtl' ? 'flex-end' : 'flex-start'}
					>
						<Text>{t('modifyNewsletter.picture')}</Text>
						<Switch
							isCheck={newslatter.isShowVideo}
							toggleIsCheck={() => {
								if (newslatter.isShowVideo) {
									setNewslatter({
										...newslatter,
										link: '',
										isShowVideo: false,
									})
								} else {
									setFile(null)
									setNewslatter({
										...newslatter,
										link: '',
										isShowVideo: true,
										filePath: '',
									})
								}
							}}
							children={null}
						/>
						<Text>{t('modifyNewsletter.video')}</Text>
					</Box>
					{newslatter.isShowVideo ? (
						<Box width={'full'}>
							<Text
								fontSize={'14px'}
								fontWeight='400'
								color={'#aaa'}
								lineHeight='19px'
								pb={'5px'}
							>
								{t('modifyNewsletter.video')}
							</Text>
							<Input
								bg={'#fff'}
								height={'52px'}
								border='1.2521px solid #E2E2E2'
								fontSize={'18px'}
								fontWeight='400'
								maxLength={128}
								placeholder={t('modifyNewsletter.linkTo') + ' YouTube'}
								isRequired
								value={newslatter.link}
								disabled={!newslatter.isShowVideo}
								onChange={(e) => setNewslatter({ ...newslatter, link: e.target.value })}
							/>
						</Box>
					) : (
						<Box width={'full'}>
							<Text
								fontSize={'14px'}
								fontWeight='400'
								color={'#aaa'}
								lineHeight='19px'
								pb={'5px'}
							>
								{t('modifyNewsletter.picture')}
							</Text>
							<Box
								height={'52px'}
								bg='#fff'
								rounded={'10px'}
								border='1.2521px solid #E2E2E2'
								display={'flex'}
								paddingBlock='9px'
								paddingInline='12px'
								justifyContent='space-between'
								textAlign='center'
								alignItems='center'
							>
								<Text
									textOverflow={'ellipsis'}
									overflow='hidden'
									whiteSpace={'nowrap'}
									fontSize={'14px'}
									paddingInlineEnd='10px'
									fontWeight='400'
									color={'#aaa'}
								>
									{file || newslatter.filePath
										? file
											? file.name
											: newslatter.fileName
										: t('modifyNewsletter.choosePicture')}
								</Text>
								<Button
									fontSize={14}
									noOfLines={1}
									onClick={() => uploadFile()}
									color={'white'}
									flexShrink={0}
									bg={file || newslatter.filePath ? 'red' : '#3FB2D2'}
									disabled={newslatter.isShowVideo}
								>
									{file || newslatter.filePath
										? t('modifyNewsletter.deletePicture')
										: t('modifyNewsletter.choosePicture')}
								</Button>
							</Box>
						</Box>
					)}
				</Box>
				<Box
					display={'flex'}
					flexDirection='column'
					gap={'21px'}
					rounded={'10px'}
					padding={'21px'}
					bg='#F5F8FA'
				>
					<Text
						fontSize={'22px'}
						fontWeight='600'
						color={'#0E314E'}
						lineHeight='30px'
					>
						{t('modifyNewsletter.recipients')}
					</Text>
					<Box>
						<Text
							fontSize={'14px'}
							fontWeight='400'
							color={'#aaa'}
							lineHeight='19px'
							pb={'5px'}
						>
							{t('modifyNewsletter.recipients')}
						</Text>
						<Box position={'relative'}>
							<Menu closeOnSelect={false}>
								<MenuButton as={Box}>
									<Box
										bg={'#fff'}
										border='1.2521px solid #E2E2E2'
										fontSize={'18px'}
										fontWeight='400'
										width='100%'
										rounded={'7px'}
										display='flex'
										alignItems='center'
										justifyContent={'space-between'}
										flexDirection='row'
										paddingBlock='9px'
										paddingInline='12px'
									>
										{selectedCompaniesIds.length > 0 ? (
											<Text
												fontSize={'18px'}
												fontWeight='400'
												color={'#aaa'}
											>
												({t('modifyNewsletter.recipients') + ' ' + selectedCompaniesIds.length})
											</Text>
										) : (
											<Text
												fontSize={'18px'}
												fontWeight='400'
												color={'#aaa'}
											>
												({t('modifyNewsletter.companyName')})
											</Text>
										)}
										<ArrowDownIcon />
									</Box>
								</MenuButton>
								<div className='companiesParent'>
									<MenuList
										width={'100%'}
										maxHeight={300}
										overflow={'scroll'}
									>
										<MenuItem>
											<Button
												variant='link'
												color='blue.400'
												onClick={() => {
													setSelectedCompaniesIds(
														selectedCompaniesIds.length === companies.length
															? []
															: companies.map((i: any) => i.id)
													)
												}}
											>
												{t('modifyNewsletter.selectAll')}
											</Button>
										</MenuItem>

										{companies.map((company: any) => {
											return (
												<MenuItem key={company.id}>
													<Checkbox
														size='lg'
														colorScheme='white'
														borderColor='#3FB2D2'
														iconColor='#3FB2D2'
														iconSize={'17px'}
														isChecked={selectedCompaniesIds.includes(company.id)}
														onChange={() => {
															setSelectedCompaniesIds(
																selectedCompaniesIds.includes(company.id)
																	? selectedCompaniesIds.filter(
																			(i: any) => i !== company.id
																	  )
																	: [...selectedCompaniesIds, company.id]
															)
														}}
													>
														{company.name}
													</Checkbox>
												</MenuItem>
											)
										})}
									</MenuList>
								</div>
							</Menu>
						</Box>
					</Box>
					{isShouldSelectRoles && selectedCompanyRoles && (
						<Box>
							<Text
								fontSize={'14px'}
								fontWeight='400'
								color={'#aaa'}
								lineHeight='19px'
								marginTop={'21px'}
								pb={'5px'}
							>
								{t('modifyGuidingQuestionnaire.recipientsRoles')}
							</Text>
							<Box position={'relative'}>
								<Menu closeOnSelect={false}>
									<MenuButton as={Box}>
										<Box
											bg={'#fff'}
											border='1.2521px solid #E2E2E2'
											fontSize={'18px'}
											fontWeight='400'
											width='100%'
											rounded={'7px'}
											display='flex'
											alignItems='center'
											justifyContent={'space-between'}
											flexDirection='row'
											paddingBlock='9px'
											paddingInline='12px'
										>
											{selectedCompaniesIds.length > 0 ? (
												<Text
													fontSize={'18px'}
													fontWeight='400'
													color={'#aaa'}
												>
													(
													{`${t('modifyGuidingQuestionnaire.recipientsRoles')} ${
														selectedRolesIds.length
													}`}
													)
												</Text>
											) : (
												<Text
													fontSize={'18px'}
													fontWeight='400'
													color={'#aaa'}
												>
													({t('modifyGuidingQuestionnaire.recipientsRoles')})
												</Text>
											)}
											<ArrowDownIcon />
										</Box>
									</MenuButton>
									<div className='companiesParent'>
										<MenuList
											width={'100%'}
											maxHeight={300}
											overflow={'scroll'}
										>
											<MenuItem>
												<Button
													variant='link'
													color='blue.400'
													onClick={() => {
														setSelectedRolesIds(
															selectedRolesIds.length === selectedCompanyRoles.length
																? []
																: selectedCompanyRoles
																		.map((i) => i.id)
																		.filter((id): id is string => !!id)
														)
													}}
												>
													{t('modifyGuidingQuestionnaire.selectAll')}
												</Button>
											</MenuItem>

											{selectedCompanyRoles.map((role) => {
												return (
													<MenuItem key={role.id}>
														<Checkbox
															size='lg'
															colorScheme='white'
															borderColor='#3FB2D2'
															iconColor='#3FB2D2'
															iconSize={'17px'}
															// @ts-ignore
															isChecked={selectedRolesIds.includes(role.id)}
															onChange={() => {
																setSelectedRolesIds(
																	// @ts-ignore
																	selectedRolesIds.includes(role.id)
																		? selectedRolesIds.filter((i) => i !== role.id)
																		: [...selectedRolesIds, role.id]
																)
															}}
														>
															{role.name}
														</Checkbox>
													</MenuItem>
												)
											})}
										</MenuList>
									</div>
								</Menu>
							</Box>
						</Box>
					)}
				</Box>
				<Box
					display={'flex'}
					flexDirection='column'
					gap={'21px'}
					rounded={'10px'}
					padding={'21px'}
					bg='#F5F8FA'
				>
					<Text
						fontSize={'22px'}
						fontWeight='600'
						color={'#0E314E'}
						lineHeight='30px'
					>
						{t('modifyNewsletter.content')}
					</Text>
					<Box>
						<Text
							fontSize={'14px'}
							fontWeight='400'
							color={'#aaa'}
							lineHeight='19px'
							pb={'5px'}
						>
							{t('modifyNewsletter.title')}
						</Text>
						<Textarea
							bg={'#fff'}
							border='1.2521px solid #E2E2E2'
							fontSize={'18px'}
							fontWeight='400'
							placeholder={t('modifyNewsletter.enterContent')}
							isRequired
							value={newslatter.content}
							onChange={(e) => setNewslatter({ ...newslatter, content: e.target.value })}
							minHeight={400}
						/>
					</Box>
				</Box>
				<Box
					display={'flex'}
					flexDirection='row-reverse'
				>
					<Button
						color={'white'}
						minW={isPc ? '200px' : 'full'}
						height='60px'
						textAlign='center'
						border='3px solid #FFFFFF'
						background={'#3FB2D2'}
						fontSize='18px'
						fontWeight={700}
						rounded={'100px'}
						filter='drop-shadow(0px 4px 30px rgba(63, 178, 210, 0.44))'
						onClick={submit}
						isLoading={submitting}
					>
						{t('modifyNewsletter.save')}
					</Button>
				</Box>
			</Box>
		</Container>
	)
}
export default ModifyNewslattter
