const mimeTypes = {
	'txt': 'text/plain',
	'html': 'text/html',
	'css': 'text/css',
	'md': 'text/markdown',
	'js': 'application/javascript',
	'json': 'application/json',
	'png': 'image/png',
	'jpg': 'image/jpeg',
	'gif': 'image/gif',
	'svg': 'image/svg+xml',
	'pdf': 'application/pdf',
	'doc': 'application/msword',
	'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'xls': 'application/vnd.ms-excel',
	'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'ppt': 'application/vnd.ms-powerpoint',
	'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	'mp3': 'audio/mpeg',
	'wav': 'audio/wav',
	'mp4': 'video/mp4',
	'avi': 'video/x-msvideo',
	'zip': 'application/zip',
	'tar': 'application/x-tar',
	'gz': 'application/gzip',
	'rar': 'application/vnd.rar',
	'7z': 'application/x-7z-compressed',
	'exe': 'application/x-msdownload',
	'apk': 'application/vnd.android.package-archive',
	'deb': 'application/x-debian-package',
	'rpm': 'application/x-rpm',
}

function getMimeType(extension: string) {
	const mimeEntry = mimeTypes[extension as keyof typeof mimeTypes]
	return mimeEntry || 'application/octet-stream'
}

export { getMimeType }
