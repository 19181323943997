import type { EditCompany } from '../../../types/server/generics/company'
import { CompaniesDropdown, RolesDropdown } from './Employee'
import { TrashIcon } from '../../../assets/TrashIcon'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Input,
	Switch,
	Text,
} from '@chakra-ui/react'

const PhoneEmployee = ({
	company,
	employee,
	setEmployee,
	setCompany,
}: {
	company: EditCompany
	employee: EditCompany['employees'][number]
	setEmployee: (company: EditCompany['employees'][number]) => void
	setCompany: (company: EditCompany) => void
}) => {
	const { t } = useTranslation()

	return (
		<Box
			display={'flex'}
			flexDirection='column'
			gap={'20px'}
			bg='white'
			paddingInline={'15px'}
			paddingTop={'15px'}
			rounded='10px'
		>
			<Box>
				<Text
					marginBottom={'5px'}
					fontSize={'14px'}
					color='#aaa'
					fontWeight='14px'
				>
					{t('modifyCompany.employeeName')}
				</Text>
				<Input
					placeholder={t('modifyCompany.name')}
					borderColor='#E2E2E2'
					bg={'white'}
					rounded={'10px'}
					fontSize='16px'
					fontWeight={400}
					paddingInline='12px'
					paddingBlock='30px'
					value={employee.name}
					onChange={(e) => {
						setEmployee({
							...employee,
							name: e.target.value,
						})
					}}
					maxLength={128}
				/>
			</Box>
			<Box>
				<Text
					marginBottom={'5px'}
					fontSize={'14px'}
					color='#aaa'
					fontWeight='14px'
				>
					{t('modifyCompany.role')}
				</Text>
				<RolesDropdown
					company={company}
					employee={employee}
					setEmployee={setEmployee}
				/>
			</Box>

			<Accordion
				border={'none'}
				allowMultiple={true}
			>
				<AccordionItem border={'none'}>
					<AccordionButton
						display={'flex'}
						justifyContent={'center'}
						alignItems={'center'}
						fontSize='16px'
						fontWeight={400}
						color='#3FB2D2'
						gap={'9px'}
					>
						<Box
							display={'flex'}
							justifyContent='center'
							alignItems={'center'}
							fontSize='16px'
							fontWeight={400}
							color='#3FB2D2'
							gap={'9px'}
						>
							{t('modifyCompany.moreDetails')}
							<AccordionIcon />
						</Box>
					</AccordionButton>
					<AccordionPanel
						display={'flex'}
						flexDirection='column'
						gap={'20px'}
						paddingInline='0px'
						paddingBottom={'15px'}
					>
						<Box>
							<Text
								marginBottom={'5px'}
								fontSize={'14px'}
								color='#aaa'
								fontWeight='14px'
							>
								{t('modifyCompany.phone')}
							</Text>
							<PhoneInput
								country={'il'}
								inputClass='login-phone-input'
								value={employee.phone}
								onChange={(phoneNumber) => {
									setEmployee({
										...employee,
										phone: phoneNumber,
									})
								}}
							/>
						</Box>
						<Box>
							<Text
								marginBottom={'5px'}
								fontSize={'14px'}
								color='#aaa'
								fontWeight='14px'
							>
								{t('modifyCompany.email')}
							</Text>
							<Input
								placeholder={t('modifyCompany.email')}
								borderColor='#E2E2E2'
								bg={'white'}
								rounded={'10px'}
								fontSize='16px'
								fontWeight={400}
								paddingInline='12px'
								paddingBlock='30px'
								value={employee.email}
								onChange={(e) => {
									setEmployee({
										...employee,
										email: e.target.value,
									})
								}}
								maxLength={50}
							/>
						</Box>

						<Box>
							<Text
								marginBottom={'5px'}
								fontSize={'14px'}
								color='#aaa'
								fontWeight='14px'
							>
								{t('modifyCompany.subCompanies')}
							</Text>
							<CompaniesDropdown
								employee={employee}
								setEmployee={setEmployee}
							/>
						</Box>

						<Box>
							<Text
								marginBottom={'5px'}
								fontSize={'14px'}
								color='#aaa'
								fontWeight='14px'
							>
								{t('modifyCompany.reporting')}
							</Text>
							<Text
								color={'#2D2F32'}
								fontSize='18px'
								fontWeight={'400'}
							>
								{
									// @ts-ignore
									(company?.roles || [])
										?.filter((r) => r.id && employee.roleIds?.some((id) => id === r.id))
										.map((role) =>
											role.parentIds?.map((x) => company.roles.find((r) => r.id === x)?.name)
										)
										.reduce((acc, rolesNames) => {
											if (!acc) acc = []

											rolesNames?.forEach((r) => {
												if (!acc.some((name) => name === r)) acc.push(r)
											})

											return acc
										}, [] as string[])
										.join(', ')
								}
							</Text>
						</Box>
						<Box>
							<Text
								marginBottom={'5px'}
								fontSize={'14px'}
								color='#aaa'
								fontWeight='14px'
							>
								{t('modifyCompany.active')}
							</Text>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row-reverse',
								}}
								dir='ltr'
							>
								<Switch
									defaultChecked={employee?.isActive}
									onChange={() => setEmployee({ ...employee, isActive: !employee?.isActive })}
								/>
							</Box>
						</Box>
						<Box
							display={'flex'}
							flexDirection='row-reverse'
						>
							<Button
								color='#3FB2D2'
								bg={'transparent'}
								display='flex'
								alignItems={'center'}
								gap='8px'
								onClick={() => {
									setCompany({
										...company,
										employees: company.employees.filter((r) => r.id !== employee.id),
									})
								}}
							>
								{t('modifyCompany.delete')}
								<TrashIcon />
							</Button>
						</Box>
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
		</Box>
	)
}

export default PhoneEmployee
