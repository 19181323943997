import {
  Box,
  Button,
  Checkbox,
  Container,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SlideFade,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import style from '../../assets/style.json';
import { useContext, useEffect, useMemo, useState } from 'react';
import CompanyService from '../../services/company-service';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowDownIcon } from '../../assets/ArrowDownIcon';
import { LanguageContext } from '../../locales/LocaleContext';

function ModifyQuestionnaire() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [questions, setQuestions] = useState<any[]>([]);
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const [isLoad, toggleISLoad] = useState(false);
  const [lang, setLang] = useState('he');
  const [isLoading, setIsLoading] = useState(false);

  const onAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        content: '',
        isRequired: false,
        options: ['', ''],
      },
    ]);
  };

  const updateQuestionByIndex = (index: number, question: any) => {
    const newQuestions = [...questions];
    newQuestions[index] = question;
    setQuestions(newQuestions);
  };

  useEffect(() => {
    async function getById(id: string) {
      toggleISLoad(true);
      try {
        const questionnaire =
          await CompanyService.getOnboardingQuestionnaireById(id);
        setQuestions(questionnaire.questions);
        setName(questionnaire.name);
        setLang(questionnaire.lang ?? 'he');
      } finally {
        toggleISLoad(false);
      }
    }
    if (id) getById(id);
  }, [id]);

  const removeQuestionByIndex = (index: number) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const onSubmit = async () => {
    if (isLoad) return;
    if (isDisabled) return;
    if (isLoading) return;

    setIsLoading(true);

    if (id)
      await CompanyService.updateOnboardingQuestionnaire(
        id,
        questions,
        name,
        lang,
      );
    else
      await CompanyService.createOnboardingQuestionnaire(questions, name, lang);

    setIsLoading(false);
    navigate('/admin/onboarding');
  };

  const isDisabled = useMemo(() => {
    if (questions.length === 0) return true;
    return questions.some((question) => {
      if (question.content === '') return true;
      if (question.options.length < 2) return true;
      return question.options.some((option: any) => !option.trim());
    });
  }, [questions]);

  return (
    <Container
      maxW="full"
      minH="100vh"
      position={'absolute'}
      top={'90px'}
      py={20}
      centerContent
    >
      <SlideFade in>
        <Text
          color={'white'}
          dir="rtl"
          marginBottom={'77px'}
          fontWeight={500}
          fontSize={32}
        >
          {t('modifyQuestionnaire.title')}
        </Text>
      </SlideFade>

      <Box
        padding={'21px'}
        maxW={style['pc-view']}
        w="100%"
        bg="#fff"
        boxShadow={'0px 0px 25px rgba(62, 62, 62, 0.08)'}
        rounded="10px"
        display={'flex'}
        flexDirection={'column'}
        gap="20px"
      >
        <Box
          display={'flex'}
          flexDirection="column"
          gap={'21px'}
          rounded={'10px'}
          padding={'21px'}
          bg="#F5F8FA"
        >
          <Text
            fontSize={'22px'}
            fontWeight="600"
            color={'#0E314E'}
            lineHeight="30px"
          >
            {t('modifyQuestionnaire.name')}
          </Text>
          <Input
            bg={'#fff'}
            border="1.2521px solid #E2E2E2"
            fontSize={'18px'}
            fontWeight="400"
            maxLength={50}
            placeholder={t('modifyQuestionnaire.name')}
            isRequired
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <Text
            fontSize={'22px'}
            fontWeight="600"
            color={'#0E314E'}
            lineHeight="30px"
          >
            {t('modifyQuestionnaire.lang')}
          </Text>
          <Menu matchWidth={true} size="sm" closeOnSelect={true}>
            <MenuButton>
              <Box
                bg="white"
                p={'10px'}
                rounded="10px"
                width={'100px'}
                display="flex"
                alignItems="center"
                justifyContent={'space-between'}
              >
                {lang.toUpperCase()}
                <Box px={2}>
                  <ArrowDownIcon />
                </Box>
              </Box>
            </MenuButton>
            <MenuList width={'100px !important'} minWidth={'100px !important'}>
              <MenuItem
                onClick={() => {
                  setLang('he');
                }}
                _hover={{
                  bg: '#F5F8FA',
                }}
                display="flex"
                justifyContent={'space-between'}
              >
                HE
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setLang('en');
                }}
                _hover={{
                  bg: '#F5F8FA',
                }}
                display="flex"
                justifyContent={'space-between'}
              >
                EN
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
        {questions.map((question, index) => {
          const updateQuestion = (question: any) => {
            updateQuestionByIndex(index, question);
          };

          return (
            <Box
              display={'flex'}
              flexDirection="column"
              gap={'21px'}
              rounded={'10px'}
              padding={'21px'}
              bg="#F5F8FA"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text
                  fontSize={'22px'}
                  fontWeight="600"
                  color={'#0E314E'}
                  lineHeight="30px"
                >
                  {t('modifyQuestionnaire.question')} {index + 1}
                </Text>
                <Button onClick={removeQuestionByIndex.bind(null, index)}>
                  {t('modifyQuestionnaire.deleteQuestion')}
                </Button>
              </Box>
              <Box>
                <Text
                  fontSize={'14px'}
                  fontWeight="400"
                  color={'#aaa'}
                  lineHeight="19px"
                  pb={'5px'}
                >
                  {t('modifyQuestionnaire.questionContent')}
                </Text>
                <Input
                  bg={'#fff'}
                  border="1.2521px solid #E2E2E2"
                  fontSize={'18px'}
                  fontWeight="400"
                  maxLength={256}
                  placeholder={t('modifyQuestionnaire.questionContent')}
                  isRequired
                  value={question.content}
                  onChange={(e) => {
                    updateQuestion({
                      ...question,
                      content: e.target.value,
                    });
                  }}
                />
                <Box pt={4} display="flex" gap={2}>
                  <Checkbox
                    bg={'#fff'}
                    isChecked={question.isRequired}
                    onChange={(e) => {
                      updateQuestion({
                        ...question,
                        isRequired: e.target.checked,
                      });
                    }}
                  />
                  <Text
                    fontSize={'16px'}
                    fontWeight="400"
                    color={'#aaa'}
                    lineHeight="19px"
                  >
                    {t('modifyQuestionnaire.isRequired')}
                  </Text>
                </Box>
                <Box display={'flex'} flexDirection="column" gap={2} pt={4}>
                  <Text
                    fontSize={'14px'}
                    fontWeight="400"
                    color={'#aaa'}
                    lineHeight="19px"
                    pb={'5px'}
                  >
                    {t('modifyQuestionnaire.options')}
                  </Text>
                  {question.options.map((option: any, optionIndex: number) => {
                    return (
                      <Box display="flex" gap={2}>
                        <Input
                          bg={'#fff'}
                          border="1.2521px solid #E2E2E2"
                          fontSize={'18px'}
                          fontWeight="400"
                          maxLength={50}
                          placeholder={
                            t('modifyQuestionnaire.option') +
                            ' ' +
                            (optionIndex + 1)
                          }
                          isRequired
                          value={option}
                          onChange={(e) => {
                            const newOptions = [...question.options];
                            newOptions[optionIndex] = e.target.value;
                            updateQuestion({
                              ...question,
                              options: newOptions,
                            });
                          }}
                        />
                        <Button
                          onClick={() => {
                            updateQuestion({
                              ...question,
                              options: question.options.filter(
                                (_: any, i: number) => i !== optionIndex,
                              ),
                            });
                          }}
                        >
                          {t('modifyQuestionnaire.deleteOption')}
                        </Button>
                      </Box>
                    );
                  })}
                  <Button
                    onClick={() => {
                      updateQuestion({
                        ...question,
                        options: [...question.options, ''],
                      });
                    }}
                  >
                    {t('modifyQuestionnaire.addOption')}
                  </Button>
                </Box>
              </Box>
            </Box>
          );
        })}
        <Button onClick={onAddQuestion}>
          {t('modifyQuestionnaire.addQuestion')}
        </Button>
      </Box>
      <Button
        isDisabled={isDisabled}
        maxW={style['pc-view']}
        w="100%"
        mt={4}
        onClick={onSubmit}
        isLoading={isLoading}
      >
        {t('modifyQuestionnaire.save')}
      </Button>
    </Container>
  );
}

export default ModifyQuestionnaire;
