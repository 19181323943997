import type { ExposureController } from '../types/server/endpoints/exposure'
import { appConfig } from '../configs/app'
import { useUser } from './user-service'
import fetcher from '../utils/fetcher'
import useSWR from 'swr'

export const useExposure = (id?: string) => {
	const { user } = useUser()

	const fetch = user?.isNewsletterOnly
		? {
				data: { total: 0 },
				error: null,
				isLoading: false,
		  }
		: useSWR<{ total: number }>(`${appConfig.apiUrl}/exposure${id ? `/${id || ''}` : ''}`, fetcher)

	return {
		...fetch,
		total: fetch.data?.total || 0,
	}
}

export const useExposureCompanies = (mutate?: boolean) => {
	const fetch = useSWR<ExposureController['readAll']['returns']>(
		`${appConfig.apiUrl}/exposure/all${mutate ? `?mutate=true` : ''}`,
		fetcher
	)

	return {
		...fetch,
		data: fetch.data || [],
	}
}

export const useExposureCategories = () => {
	const fetch = useSWR<ExposureController['categories']['returns']>(
		`${appConfig.apiUrl}/exposure/categories`,
		fetcher
	)

	return {
		...fetch,
		data: fetch.data || { categories: {} },
	}
}

export const useExposureCompanyCategories = (id: string) => {
	const fetch = useSWR<ExposureController['companyCategories']['returns']>(
		`${appConfig.apiUrl}/exposure/company/categories/${id}`,
		fetcher
	)

	return {
		...fetch,
		data: fetch.data || { categories: {} },
	}
}

export const useExposureCategory = (id: string) => {
	const fetch = useSWR<ExposureController['category']['returns']>(
		`${appConfig.apiUrl}/exposure/category/${id}`,
		fetcher
	)

	console.log( fetch.data )

	return {
		...fetch,
		data: fetch.data || null,
	}
}

export const useExposureCompanyCategory = (id: string, companyId: string) => {
	const fetch = useSWR<ExposureController['companyCategory']['returns']>(
		`${appConfig.apiUrl}/exposure/company/category/${id}/${companyId}`,
		fetcher
	)

	console.log({ fetch })

	return {
		...fetch,
		data: fetch.data || null,
	}
}

export const useAverageCompaniesExposure = (companies: string[]) => {
	const fetch = useSWR<ExposureController['average']['returns']>(
		companies.length ? `${appConfig.apiUrl}/exposure/average?companies=${companies.join(',')}` : undefined,
		fetcher
	)

	return {
		...fetch,
		data: fetch.data || { total: 0, average: 0 },
	}
}

// class ExposureService {
// -> useExposure
// static async read() {
// 	const response = await axios.get(`${appConfig.apiUrl}/exposure`, {
// 		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
// 	})
// 	const data = response.data
// 	return data
// }

// -> useExposure with id
// static async getById(id: string) {
// 	const response = await axios.get<{ total: number }>(`${appConfig.apiUrl}/exposure/${id}`, {
// 		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
// 	})

// 	const data = response.data

// 	return data
// }

// -> useExposureCategories
// static async categories() {
// 	const response = await axios.get(`${appConfig.apiUrl}/exposure/categories`, {
// 		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
// 	})
// 	const data = response.data
// 	return data
// }

// -> useExposureCompanyCategories
// static async companyCategories(id: string) {
// 	const response = await axios.get(`${appConfig.apiUrl}/exposure/company/categories/${id}`, {
// 		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
// 	})
// 	const data = response.data
// 	return data
// }

// -> useExposureCategory
// static async category(id: string) {
// 	const response = await axios.get(`${appConfig.apiUrl}/exposure/category/${id}`, {
// 		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
// 	})
// 	const data = response.data
// 	return data
// }

// -> useExposureCompanyCategory
// static async companyCategory(id: string, companyId: String) {
// 	const response = await axios.get(`${appConfig.apiUrl}/exposure/company/category/${id}/${companyId}`, {
// 		headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
// 	})
// 	const data = response.data
// 	return data
// }
// }

// export default ExposureService
