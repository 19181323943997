import type { EditCompany } from '../../../types/server/generics/company'
import type { BackendQuery } from '../../../types/server/utils'
import { Text, Box, Button, Table, Thead, Tbody, Tr, Th, SlideFade, useMediaQuery, Input } from '@chakra-ui/react'
import { CirclePlusIcon } from '../../../assets/CirclePlusIcon'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import Pagination from '../../../components/Pagination'
import PhoneEmployee from './PhoneEmployee'
import Employee from './Employee'

const Employees = ({ company, setCompany }: { company: EditCompany; setCompany: (company: EditCompany) => void }) => {
	const { t } = useTranslation()
	const [isPc] = useMediaQuery('(min-width: 1300px)')

	// Employees query
	const [query, setQuery] = useState<BackendQuery>({
		search: '',
		page: '0',
		limit: '10',
	})

	const filteredItems = (company.employees || []).filter((employee) => {
		if (!query.search) return true
		const search = query.search.toLowerCase()
		return (
			employee.name?.toLowerCase().includes(search) ||
			employee.phone?.toLowerCase().includes(search) ||
			employee.email?.toLowerCase().includes(search)
		)
	})

	const currentPageItems = filteredItems.slice(
		Number(query.page) * Number(query.limit),
		(Number(query.page) + 1) * Number(query.limit)
	)

	const totalPages = Math.ceil(filteredItems.length / Number(query.limit))

	return (
		<Box
			bg='#F5F8FA'
			p={'21px'}
			rounded='10px'
		>
			<SlideFade in>
				<Box
					width={'100%'}
					display='flex'
					justifyContent={'space-between'}
					alignItems='center'
					mb={'7px'}
				>
					<Text
						fontSize={'22px'}
						fontWeight='600'
					>
						{t('modifyCompany.employees')}
					</Text>
					{company?.employees && company?.employees?.length > 0 && (
						<div className='flex gap-4'>
							<Input
								bg={'#f5f8fa'}
								border={'1px solid #E2E2E2'}
								rounded='10px'
								height={'40px'}
								maxLength={128}
								placeholder={t('companies.search')}
								width={'200px'}
								isRequired
								value={query.search}
								onChange={(e) => setQuery({ ...query, search: e.target.value })}
							/>
							<Button
								minW={'188px'}
								textAlign='center'
								fontSize='18px'
								fontWeight={700}
								rounded={'100px'}
								borderWidth={'1.5px'}
								borderColor={'#3FB2D2'}
								bg={'transparent'}
								color={'#3FB2D2'}
								onClick={() => {
									setCompany({
										...company,
										employees: [
											...(company.employees || []),
											{
												id: (Math.random() + 1).toString(36).substring(2),
												isActive: true,
											},
										],
									})

									// Move to the last page
									const newPage = Math.ceil((company.employees || []).length / Number(query.limit))
									setQuery((q) => ({ ...q, page: (newPage - 1).toString() }))
								}}
							>
								<Box
									gap={'17px'}
									display={'flex'}
									alignItems={'center'}
								>
									<Text>{t('modifyCompany.addEmployee')}</Text>
									<CirclePlusIcon />
								</Box>
							</Button>
						</div>
					)}
				</Box>
				{!company?.employees?.length ? (
					<Button
						minW={'188px'}
						textAlign='center'
						fontSize='18px'
						width={'100%'}
						fontWeight={700}
						rounded={'10px'}
						borderWidth={'1.5px'}
						borderStyle='dashed'
						height='78px'
						borderColor={'#3FB2D2'}
						bg={'transparent'}
						color={'#3FB2D2'}
						onClick={() =>
							setCompany({
								...company,
								employees: [
									...(company.employees || []),
									{ id: (Math.random() + 1).toString(36).substring(2) },
								],
							})
						}
					>
						<Box
							gap={'17px'}
							display={'flex'}
							alignItems={'center'}
						>
							<Text>{t('modifyCompany.addEmployee')}</Text>
							<CirclePlusIcon />
						</Box>
					</Button>
				) : null}
				{company.employees && company.employees.length > 0 && (
					<>
						{isPc ? (
							<div className='overflow-x-scroll h-fit overflow-y-hidden min-h-96 pb-10'>
								<Table>
									<Thead borderBottom={'1.5px solid #DFDFDF'}>
										<Tr>
											<Th
												fontSize={'16px'}
												fontWeight={700}
												lineHeight={'29px'}
												paddingBottom='12px'
												textAlign='start'
											>
												{t('modifyCompany.name')}
											</Th>
											<Th
												fontSize={'16px'}
												fontWeight={700}
												lineHeight={'29px'}
												paddingBottom='12px'
												textAlign='start'
											>
												{t('modifyCompany.phone')}
											</Th>
											<Th
												fontSize={'16px'}
												fontWeight={700}
												lineHeight={'29px'}
												paddingBottom='12px'
												textAlign='start'
											>
												{t('modifyCompany.email')}
											</Th>
											<Th
												fontSize={'16px'}
												fontWeight={700}
												lineHeight={'29px'}
												paddingBottom='12px'
												textAlign='start'
											>
												{t('modifyCompany.role')}
											</Th>
											<Th
												fontSize={'16px'}
												fontWeight={700}
												lineHeight={'29px'}
												paddingBottom='12px'
												textAlign='start'
											>
												{t('modifyCompany.subCompanies')}
											</Th>
											<Th
												fontSize={'16px'}
												fontWeight={700}
												lineHeight={'29px'}
												paddingBottom='12px'
												textAlign='start'
											>
												{t('modifyCompany.reporting')}
											</Th>
											<Th
												fontSize={'16px'}
												fontWeight={700}
												lineHeight={'29px'}
												paddingBottom='12px'
												textAlign='start'
											>
												{t('modifyCompany.active')}
											</Th>
										</Tr>
									</Thead>
									<Tbody>
										{currentPageItems.map((employee, i) => {
											return (
												<Employee
													company={company}
													employee={employee}
													index={i}
													setEmployee={(e) => {
														setCompany({
															...company,
															employees: company.employees.map((emp, j) =>
																j === i ? e : emp
															),
														})
													}}
													setCompany={setCompany}
												/>
											)
										})}
									</Tbody>
								</Table>
							</div>
						) : (
							<Box
								display={'flex'}
								flexDirection='column'
								gap={'25px'}
							>
								{currentPageItems.map((employee, i) => (
									<PhoneEmployee
										company={company}
										employee={employee}
										setEmployee={(e) => {
											setCompany({
												...company,
												employees: company.employees.map((emp, j) => (j === i ? e : emp)),
											})
										}}
										setCompany={setCompany}
									/>
								))}
							</Box>
						)}
					</>
				)}
				<Pagination
					pages={totalPages}
					page={Number(query.page)}
					setPage={(page) => setQuery((q) => ({ ...q, page: page.toString() }))}
				/>
			</SlideFade>
		</Box>
	)
}

export const toggleIdFromArr = <T,>(arr: T[], id: T) => {
	if (!Array.isArray(arr)) return [id]
	if (arr.some((item) => item === id)) return arr.filter((item) => item !== id)
	return [...arr, id]
}

export default Employees
