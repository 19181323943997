import type { Company } from '../../types/server/generics/company'
import { useAverageCompaniesExposure, useExposure, useExposureCategories } from '../../services/exposure-service'
import { PrimaryButton } from '../../components/Footer/styleComponent/PrimaryButton'
import { useGetCompanies } from '../../services/company-service'
import { useUser } from '../../services/user-service'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import {
	Box,
	CircularProgress,
	CircularProgressLabel,
	Container,
	SimpleGrid,
	Text,
	VStack,
	SlideFade,
	ScaleFade,
	useMediaQuery,
} from '@chakra-ui/react'
import style from '../../assets/style.json'

const ExposureByCompanies = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const { user } = useUser()

	const [isPc] = useMediaQuery('(min-width: 1300px)')

	useEffect(() => {
		if (user) {
			if (!user.subCompanies?.length) {
				navigate('/exposure')
			}
		}
	}, [user])

	const userCompanies = user?.subCompanies || []

	const { data: companies } = useGetCompanies(userCompanies)
	const { data: categories } = useExposureCategories()
	const {
		data: { average },
	} = useAverageCompaniesExposure(userCompanies)

	return (
		<Container
			maxW='full'
			position='absolute'
			top={'90px'}
			py={20}
			centerContent
		>
			<SlideFade in>
				<Text
					color={'white'}
					dir='rtl'
					marginBottom={'77px'}
					fontWeight={500}
					fontSize={32}
				>
					{t('exposure.performance')}
				</Text>
			</SlideFade>
			<Box
				w='100%'
				maxW={style['pc-view']}
				bg='#fff'
				p={6}
				rounded='10px'
				boxShadow={'0px 0px 25px rgba(62, 62, 62, 0.08)'}
			>
				<SlideFade in>
					<Box
						w='100%'
						display={'flex'}
						flexDirection={isPc ? 'row' : 'column'}
						justifyContent='center'
						alignItems='center'
						gap={isPc ? '30px' : '10px'}
						paddingBottom='20px'
					>
						<Box
							textAlign={isPc ? 'start' : 'center'}
							paddingTop='25px'
						>
							<Text
								lineHeight={isPc ? '60px' : '25px'}
								fontWeight='400'
								color={'#A9A9A9'}
								fontSize={18}
							>
								{t('exposure.generalPerformance')}
							</Text>
						</Box>
						<CircularProgress
							mt={3}
							value={average * 100}
							size='140px'
							thickness='10px'
							color={average > 0.8 ? '#3FB2D2' : average > 0.4 ? '#F6A860' : '#E55353'}
						>
							<CircularProgressLabel
								fontWeight='bold'
								fontSize={average === null ? 15 : 22}
								opacity={average === null ? 0.8 : 1}
							>
								{Object.keys(categories).length === 0
									? t('exposure.processing') + '...'
									: (average * 100).toFixed(0) + '%'}
							</CircularProgressLabel>
						</CircularProgress>
					</Box>
				</SlideFade>

				<Text
					lineHeight={isPc ? '60px' : '18px'}
					fontWeight='400'
					fontSize={18}
					textAlign={isPc ? 'center' : 'start'}
					color='#A9A9A9'
				>
					{t('exposure.companies')}
				</Text>

				<Box
					w='100%'
					minH={240}
					py={'21px'}
				>
					<SimpleGrid
						w='100%'
						columns={isPc ? 3 : 1}
						spacing={4}
					>
						{companies.map((company) => (
							<CompanyExposure
								company={company}
								key={company.id}
							/>
						))}
					</SimpleGrid>
				</Box>
			</Box>
		</Container>
	)
}

const CompanyExposure = ({ company }: { company: Company }) => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const { data: exposure } = useExposure(company.id)

	return (
		<Box
			bg='#F5F8FA'
			w='100%'
			display={'flex'}
			justifyContent='space-between'
			padding={'20px'}
			rounded='10px'
		>
			<Box
				height={'100%'}
				display='flex'
				flexDirection={'column'}
				justifyContent='space-between'
				alignItems={'flex-start'}
			>
				<Text
					fontWeight='600'
					fontSize={18}
					opacity={1}
					color='#0E314E'
				>
					{company.name}
				</Text>
				<PrimaryButton onClick={() => navigate(`/admin/exposure/${company.id}`)}>
					{t('exposure.check')}
				</PrimaryButton>
			</Box>
			<Graph exposure={exposure?.total || 0} />
		</Box>
	)
}

const Graph = ({ exposure }: { exposure: number }) => {
	return (
		<ScaleFade
			in
			initialScale={0}
		>
			<VStack
				w='100%'
				spacing={1}
			>
				<CircularProgress
					value={(exposure || 0) * 100}
					size='100px'
					thickness='10px'
					color={exposure > 0.8 ? '#3FB2D2' : exposure > 0.4 ? '#F6A860' : '#E55353'}
				>
					<CircularProgressLabel
						fontWeight='bold'
						fontSize={19}
					>
						{((exposure || 0) * 100).toFixed(0)}%
					</CircularProgressLabel>
				</CircularProgress>
			</VStack>
		</ScaleFade>
	)
}

export default ExposureByCompanies
