import {
  Box,
  Button,
  Img,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ArrowDownIcon } from '../assets/ArrowDownIcon';
// import logo from '../assets/logo-dark.png';
import logo from '../assets/new-logo-dark.png';
import bg from '../assets/bgLogin.png';
import { LogoutIcon } from '../assets/LogoutIcon';
import AuthService from '../services/auth-service';
import { useContext, useRef } from 'react';
import useClickOutside from '../hooks/useClickOutside';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../locales/LocaleContext';
import Swal from 'sweetalert2';

export function Sidebar({
  userInfo,
  currRole,
  userRoles,
  setCurrRole,
  setIsSidebarOpen,
  isNewsletterOnly,
}: {
  userInfo: any;
  currRole: any;
  userRoles: any;
  setCurrRole: any;
  setIsSidebarOpen: any;
  isNewsletterOnly: boolean;
}) {
  const { t } = useTranslation();
  const { locale, setLocale } = useContext(LanguageContext);

  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => {
    if (setIsSidebarOpen) setIsSidebarOpen(false);
  });

  const handleOpenRedButtonPopup = () => {
    Swal.fire('', t('sidebar.redButtonMsg'), 'error');
  };

  return (
    <Box
      ref={ref}
      maxWidth="380px"
      width="full"
      bg="white"
      boxShadow="0px 3.43713px 25.7784px rgba(0, 0, 0, 0.25)"
      height="full"
      overflow={'auto'}
      position="fixed"
      top="0"
      left="0"
      zIndex={'999'}
      paddingTop={'45px'}
      alignItems="center"
      backgroundImage={bg}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="bottom"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box width="full">
        <Box
          display="flex"
          alignItems="center"
          justifyContent={'space-between'}
          width="full"
          paddingInline="20px"
        >
          <Img width={'155px'} src={logo} />
          <Button
            onClick={() => {
              setIsSidebarOpen(false);
            }}
            fontSize="26px"
            bg="transparent"
          >
            ✕
          </Button>
        </Box>

        {/* <Box display="flex" justifyContent="space-between" alignItems="center" width="full" padding="20px">
          <Text>{i18n.language === 'en'? 'EN' : 'HE'}</Text>
          <Switch isChecked={i18n.language === 'he'} onChange={toggleLanguage} colorScheme="teal" />
        </Box> */}

        <Box
          justifyContent={'center'}
          paddingTop="45px"
          display="flex"
          alignItems={'center'}
        >
          <Box display="flex" alignItems={'center'} gap="16px">
            <Img
              src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
              width="50px"
              rounded="full"
              h="auto"
              boxShadow="0px 3px 6px rgba(61, 165, 194, 0.2)"
            />
            <Menu>
              <MenuButton>
                <Box
                  display={'flex'}
                  flexDirection="row"
                  textAlign={'right'}
                  alignItems="center"
                  gap={'20px'}
                >
                  <Box>
                    <Text fontSize="18px" fontWeight="700">
                      {userInfo?.name}
                    </Text>
                    <Text color={'#20273D'} fontSize="16px">
                      {t('sidebar.role') + ': ' + currRole?.name}
                    </Text>
                  </Box>
                  <Box paddingBottom={'5px'}>
                    <ArrowDownIcon />
                  </Box>
                </Box>
              </MenuButton>
              <MenuList overflowY="auto" maxH={'300px'}>
                {userRoles?.map((role: any) => (
                  <MenuItem
                    // color="red.600"
                    _hover={{
                      bg: '#F5F8FA',
                    }}
                    display="flex"
                    justifyContent={'space-between'}
                    onClick={() => {
                      setCurrRole(role);
                    }}
                  >
                    <Box>{role.name}</Box>
                    {role.id === currRole?.id ? (
                      <Box
                        display={'flex'}
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Text fontSize="14px" fontWeight="700">
                          ✓
                        </Text>
                      </Box>
                    ) : null}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Box>
        </Box>

        <Box
          marginTop={'37px'}
          width="full"
          flexDirection={'column'}
          display="flex"
          className="sidebar-items"
        >
          <NavLink
            onClick={() => {
              setIsSidebarOpen(false);
            }}
            className="sidebar-item"
            to="/home"
          >
            {t('sidebar.main')}
          </NavLink>
          {(currRole?.showNewslatter || isNewsletterOnly) && (
            <NavLink
              onClick={() => {
                setIsSidebarOpen(false);
              }}
              className="sidebar-item"
              to="/newslatter"
            >
              {t('sidebar.training')}
            </NavLink>
          )}
          {userInfo.companyId === '123' && (
            <NavLink
              onClick={() => {
                setIsSidebarOpen(false);
              }}
              className="sidebar-item"
              to="/admin/reports"
            >
              {t('sidebar.history')}
            </NavLink>
          )}
          <NavLink
            onClick={() => {
              setIsSidebarOpen(false);
            }}
            className="sidebar-item"
            to="/about"
          >
            {t('sidebar.about')}
          </NavLink>
          <NavLink
            onClick={() => {
              setIsSidebarOpen(false);
            }}
            className="sidebar-item"
            to="/sanctions"
          >
            {t('sidebar.sanctions')}
          </NavLink>
          {userInfo.companyId === '123' && (
            <NavLink
              onClick={() => {
                setIsSidebarOpen(false);
              }}
              className="sidebar-item"
              to="/admin/onboarding"
            >
              {t('sidebar.onboardingManagement')}
            </NavLink>
          )}
          <NavLink
            onClick={() => {
              setIsSidebarOpen(false);
            }}
            className="sidebar-item"
            to="/p-term-of-use"
          >
            {t('sidebar.termsOfService')}
          </NavLink>
          <NavLink
            onClick={() => {
              setIsSidebarOpen(false);
            }}
            className="sidebar-item"
            to="/p-privacy-policy"
          >
            {t('sidebar.privacyPolicy')}
          </NavLink>
          {/* <NavLink
            onClick={() => {
              handleOpenRedButtonPopup();
              setIsSidebarOpen(false);
            }}
            className="sidebar-item"
            // to="/red-button"
            to="#"
          >
            {t('sidebar.redButton')}
          </NavLink> */}
          {userInfo.companyId === '123' && (
            <NavLink
              onClick={() => {
                setIsSidebarOpen(false);
              }}
              className="sidebar-item"
              to="/create-md"
            >
              {t('sidebar.createMd')}
            </NavLink>
          )}
          <NavLink
            onClick={() => {
              setIsSidebarOpen(false);
            }}
            className="sidebar-item"
            to="/contact"
          >
            {t('sidebar.contactUs')}
          </NavLink>
        </Box>
      </Box>
      <Box>
        <Button
          bg={'transparent'}
          fontWeight={400}
          color="#E55353"
          display="flex"
          gap="20px"
          marginBottom={'40px'}
          onClick={async () => {
            await AuthService.logout();
            setCurrRole(null);
            setIsSidebarOpen(false);
            navigate('/');
          }}
        >
          <LogoutIcon />
          <Text color="#E55353">{t('sidebar.signOut')}</Text>
        </Button>
      </Box>
    </Box>
  );
}
