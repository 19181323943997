import type { CompanyWithRoles } from '../../types/server/generics/company'
import type { BackendQuery } from '../../types/server/utils'
import {
	Box,
	Button,
	Container,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	HStack,
	SlideFade,
	Td,
	ScaleFade,
	CircularProgress,
	Skeleton,
	Input,
} from '@chakra-ui/react'
// import { useContext, useEffect, useState } from 'react'
import CompanyService, { useListCompanies } from '../../services/company-service'
import { useExposureCompanies } from '../../services/exposure-service'
import { CirclePlusIcon } from '../../assets/CirclePlusIcon'
import { TaskListIcon } from '../../assets/TakListIcon'
import { ReportIcon } from '../../assets/ReportIcon'
import { TrashIcon } from '../../assets/TrashIcon'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AIIcon } from '../../assets/AIIcon'
import { useState } from 'react'
// import { LanguageContext } from '../../locales/LocaleContext'
// import { getCache, setCache } from '../../services/cache'
import useDebounce from '../../utils/hooks/useDebounce'
import Pagination from '../../components/Pagination'
import style from '../../assets/style.json'
import Swal from 'sweetalert2'

const Companies = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const handleCopyCompany = async (company: CompanyWithRoles) => {
		const result = await Swal.fire({
			title: t('companies.copyCompany').replace('{name}', company.name),
			// text: "לאחר המחיקה לא יהיה ניתן לשחזר את המידע.",
			input: 'text',
			inputAttributes: {
				placeholder: company.name,
			},
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			cancelButtonText: t('companies.cancel'),
			confirmButtonText: t('companies.copy'),
		})

		if (result.isConfirmed) {
			console.log(result)

			try {
				await CompanyService.copy(company.id, result.value)
				mutate()
			} catch (err: any) {
				Swal.fire('', err.response.data.message, 'error')
			}
		}
	}

	const [query, setQuery] = useState<BackendQuery>({
		search: '',
		page: '0',
		limit: '10',
	})

	const debouncedQuery = useDebounce(query, 500)

	// total = total companies
	const { data: companies, total, isLoading, mutate } = useListCompanies(debouncedQuery)
	const { data: exposure } = useExposureCompanies()

	const totalPages = Math.ceil(total / Number(query.limit))

	const companiesWithExposure = companies.map((company) => ({
		...company,
		exposure: exposure.find((item: any) => item.companyId === company.id)?.exposure || 0,
	}))

	return (
		//@ts-ignore
		<Container
			maxW={'full'}
			minH='100vh'
			py={20}
			position='absolute'
			top={'90px'}
			centerContent
		>
			<SlideFade in>
				<Text
					color={'white'}
					dir='rtl'
					marginBottom={'77px'}
					fontWeight={500}
					fontSize={32}
				>
					{t('companies.companies')}
				</Text>
			</SlideFade>

			<SlideFade in>
				<Box
					w='90vw'
					maxW={style['pc-view']}
					boxShadow='0px 0px 25px rgba(62, 62, 62, 0.08)'
					bg='#fff'
					p={'20px'}
					rounded='10px'
				>
					<div className='flex justify-between items-center mb-7'>
						<Button
							onClick={() => navigate('/admin/company')}
							minW={'188px'}
							textAlign='center'
							fontSize='18px'
							fontWeight={700}
							rounded={'100px'}
							borderWidth={'1.5px'}
							borderColor={style['primary-color']}
							bg={'#fff'}
							color={style['primary-color']}
							isDisabled={isLoading}
						>
							<Box
								gap={'17px'}
								display={'flex'}
								alignItems={'center'}
							>
								<Text>{t('companies.addCompany')}</Text>
								<CirclePlusIcon />
							</Box>
						</Button>
						<Input
							bg={'#f5f8fa'}
							border={'1px solid #E2E2E2'}
							rounded='10px'
							height={'40px'}
							maxLength={128}
							placeholder={t('companies.search')}
							width={'300px'}
							isRequired
							value={query.search}
							onChange={(e) => setQuery({ ...query, search: e.target.value })}
						/>
					</div>
					<Box overflowX={'auto'}>
						{isLoading ? (
							<Table>
								<Thead borderBottom={'1.5px solid #DFDFDF'}>
									<Tr>
										<Th
											fontSize={'16px'}
											fontWeight={700}
											lineHeight={'29px'}
											paddingBottom='12px'
											textAlign='start'
											position='sticky'
											right={'0'}
											zIndex={5}
											bg='white'
										>
											<Skeleton
												height='20px'
												width='100px'
											/>
										</Th>
										<Th
											fontSize={'16px'}
											fontWeight={700}
											lineHeight={'29px'}
											paddingBottom='12px'
											textAlign='start'
										>
											<Skeleton
												height='20px'
												width='100px'
											/>
										</Th>
										<Th
											fontSize={'16px'}
											fontWeight={700}
											lineHeight={'29px'}
											paddingBottom='12px'
											textAlign='start'
										>
											<Skeleton
												height='20px'
												width='100px'
											/>
										</Th>
										<Th
											fontSize={'16px'}
											fontWeight={700}
											lineHeight={'29px'}
											paddingBottom='12px'
											textAlign='start'
										></Th>
									</Tr>
								</Thead>
								<Tbody>
									{[...Array(3)].map((_, i) => (
										<Tr
											key={i}
											rounded={'10px'}
											border={'none'}
										>
											<Td
												cursor='pointer'
												roundedRight='10px'
												bg={i % 2 === 0 ? 'white' : '#FFF'}
												border={'none'}
												textAlign='start'
												position='sticky'
												right={'0'}
												zIndex={5}
											>
												<Skeleton
													height='20px'
													width='100px'
												/>
											</Td>
											<Td
												bg={i % 2 === 0 ? 'white' : '#FFF'}
												border={'none'}
												textAlign='start'
											>
												<Skeleton
													height='20px'
													width='150px'
												/>
											</Td>
											<Td
												cursor='pointer'
												maxW='200px'
												bg={i % 2 === 0 ? 'white' : '#FFF'}
												border={'none'}
												textAlign='start'
											>
												<Skeleton
													height='40px'
													width='40px'
													borderRadius='50%'
												/>
											</Td>
											<Td
												bg={i % 2 === 0 ? 'white' : '#FFF'}
												border={'none'}
												textAlign='start'
												roundedLeft={'10px'}
											>
												<HStack
													spacing={3}
													justifyContent='center'
												>
													<Skeleton
														height='40px'
														width='40px'
													/>
													<Skeleton
														height='40px'
														width='40px'
													/>
													<Skeleton
														height='40px'
														width='40px'
													/>
												</HStack>
											</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
						) : (
							<Table>
								<Thead borderBottom={'1.5px solid #DFDFDF'}>
									<Tr>
										<Th
											fontSize={'16px'}
											fontWeight={700}
											lineHeight={'29px'}
											paddingBottom='12px'
											textAlign='start'
											position='sticky'
											right={'0'}
											zIndex={5}
											bg='white'
										>
											{t('companies.name')}
										</Th>
										<Th
											fontSize={'16px'}
											fontWeight={700}
											lineHeight={'29px'}
											paddingBottom='12px'
											textAlign='start'
										>
											{t('companies.field')}
										</Th>
										<Th
											fontSize={'16px'}
											fontWeight={700}
											lineHeight={'29px'}
											paddingBottom='12px'
											textAlign='start'
										>
											{t('companies.performance')}
										</Th>
										<Th
											fontSize={'16px'}
											fontWeight={700}
											lineHeight={'29px'}
											paddingBottom='12px'
											textAlign='start'
										></Th>
									</Tr>
								</Thead>
								<Tbody>
									{companiesWithExposure.map((company, i) => (
										<Tr
											key={'company-' + company.id + i}
											rounded={'10px'}
											border={'none'}
										>
											<Td
												onClick={() => navigate(`/admin/company/${company.id}`)}
												cursor='pointer'
												roundedRight='10px'
												bg={i % 2 === 0 ? 'white' : '#F5F8FA'}
												border={'none'}
												textAlign='start'
												position='sticky'
												right={'0'}
												zIndex={5}
											>
												{/* Strip the name by X chars and add ... */}
												{company.name.length > 25 ? company.name.slice(0, 25) : company.name}
												{company.name.length > 25 ? '...' : ''}
											</Td>
											<Td
												bg={i % 2 === 0 ? 'white' : '#F5F8FA'}
												border={'none'}
												textAlign='start'
											>
												{company.field}
											</Td>
											<Td
												onClick={() => navigate(`/admin/exposure/${company.id}`)}
												cursor='pointer'
												maxW='200px'
												bg={i % 2 === 0 ? 'white' : '#F5F8FA'}
												border={'none'}
												textAlign='start'
											>
												{!isNaN(+company.exposure) ? (
													<ScaleFade
														in
														initialScale={0}
													>
														<HStack spacing={1}>
															<Text
																textAlign={'end'}
																width='50px'
															>
																{(company.exposure * 100).toFixed(0)}%
															</Text>
															<CircularProgress
																value={company.exposure * 100}
																size='40px'
																thickness='10px'
																color={
																	company.exposure * 100 < 25
																		? 'red.500'
																		: company.exposure * 100 < 50
																		? 'yellow.300'
																		: company.exposure * 100 < 85
																		? '#F6A860'
																		: '#3FB2D2'
																}
															/>
														</HStack>
													</ScaleFade>
												) : (
													<Skeleton
														height='40px'
														width='40px'
														borderRadius='50%'
													/>
												)}
											</Td>
											<Td
												bg={i % 2 === 0 ? 'white' : '#F5F8FA'}
												border={'none'}
												textAlign='start'
												roundedLeft={'10px'}
											>
												<HStack
													spacing={3}
													justifyContent='center'
												>
													{/* <Tooltip
                              label={t('companies.notEnoughRoles')}
                              aria-label="A tooltip"
                            > */}
													<Button
														color={'#3FB2D2'}
														bg='transparent'
														onClick={() =>
															navigate({
																pathname: `/admin/onboarding/${company.id}`,
															})
														}
														// isDisabled={company.roles.length === 0}
													>
														O
													</Button>
													{/* </Tooltip> */}
													<Button
														color={'#3FB2D2'}
														bg='transparent'
														onClick={() =>
															navigate({
																pathname: `/admin/risks-files/${company.id}`,
															})
														}
													>
														<AIIcon />
													</Button>
													<Button
														color={'#3FB2D2'}
														bg='transparent'
														onClick={() =>
															navigate({
																pathname: '/admin/tasklists',
																search: `?company_id=${company.id}`,
															})
														}
													>
														<TaskListIcon />
													</Button>

													<Button
														color={'#3FB2D2'}
														bg='transparent'
														onClick={() =>
															navigate({
																pathname: `/admin/reports/${company.id}`,
															})
														}
													>
														<ReportIcon />
													</Button>

													<Button
														fontWeight='bold'
														color={company.id !== '123' ? '#3FB2D2' : 'gray.800'}
														fontSize={17}
														bg='transparent'
														rounded='xl'
														opacity={company.id !== '123' ? 1 : 0.6}
														cursor={company.id !== '123' ? 'pointer' : 'not-allowed'}
														onClick={async () => {
															if (company.id !== '123') {
																handleCopyCompany(company)
															}
														}}
													>
														<CirclePlusIcon />
													</Button>

													<Button
														fontWeight='bold'
														color={company.id !== '123' ? '#3FB2D2' : 'gray.800'}
														fontSize={17}
														bg='transparent'
														rounded='xl'
														opacity={company.id !== '123' ? 1 : 0.6}
														cursor={company.id !== '123' ? 'pointer' : 'not-allowed'}
														onClick={async () => {
															if (company.id !== '123') {
																await CompanyService.delete(company.name, company.id)

																mutate()
															}
														}}
													>
														<TrashIcon />
													</Button>
												</HStack>
											</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
						)}
					</Box>
					<Pagination
						pages={totalPages}
						page={Number(query.page)}
						setPage={(page) => setQuery((q) => ({ ...q, page: page.toString() }))}
						isDisabled={isLoading}
					/>
				</Box>
			</SlideFade>
		</Container>
	)
}

export default Companies
