import { Button, Stack } from '@chakra-ui/react'

const Pagination = ({
	pages,
	page,
	setPage,
	isDisabled,
}: {
	pages: number
	page: number
	setPage: (page: number) => void
	isDisabled?: boolean
}) => {
	return pages > 1 && (
		<Stack
			spacing={2}
			direction='row'
			align='center'
			justifyContent='center'
			mt={8}
		>
			{[...Array(pages)].map((_, i) => (
				<Button
					key={i}
					colorScheme='teal'
					onClick={() => setPage(i)}
					variant={page === i ? 'solid' : 'outline'}
					isDisabled={isDisabled}
					size='sm'
				>
					{i + 1}
				</Button>
			))}
		</Stack>
	)
}

export default Pagination
