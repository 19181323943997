import UtilSrvice from '../../services/util-service'
import style from '../../assets/style.json'
import Employee from './Employee'
import { useExposureCategory, useExposureCompanyCategory } from '../../services/exposure-service'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
	Box,
	Container,
	Text,
	Table,
	Tr,
	Th,
	Thead,
	Tbody,
	Td,
	SlideFade,
	Flex,
	Skeleton,
	SkeletonCircle,
	SkeletonText,
} from '@chakra-ui/react'

export const isHaveRoleIdInParents = (roleId: string, parentIds: any[], allRoles: any[]): any => {
	try {
		console.log({ roleId, parentIds, allRoles })
		if (!parentIds || parentIds.length === 0) return false
		if (parentIds?.some((id) => id === roleId)) return true

		const parents = parentIds?.map((id) => allRoles.find((mapRole: any) => mapRole.id === id)).filter((x: any) => x)

		console.log('parentIds', parents, parentIds, allRoles)
		return parents.reduce((acc: boolean, parent: any) => {
			if (acc) return acc
			else return isHaveRoleIdInParents(roleId, parent?.parentIds || [], allRoles)
		}, false)
	} catch (error: any) {
		console.error(`Error in isHaveRoleIdInParents function: ${error.message}`)

		return false
	}
}

const ExposureDetailed = ({ role }: { role: any }) => {
	// const navigate = useNavigate()
	const { id, companyId } = useParams()
	const { t } = useTranslation()

	const { data, isLoading } =
		id && companyId ? useExposureCompanyCategory(id, companyId) : id ? useExposureCategory(id) : { data: null }

	// const onClickTasks = async (id: string, tasksId: string) => {
	// 	navigate(`/tasks/${tasksId}?user_id=${id}`)
	// }

	console.log({
		id,
		companyId,
		data,
		emps: data?.employeeExposures,
		test: id && companyId ? 'useExposureCompanyCategory(id, companyId)' : id ? 'useExposureCategory(id)' : '{ data: null },'
	})

	return (
		<Container
			maxW='full'
			minH='100vh'
			position='absolute'
			top={'90px'}
			py={20}
			centerContent
		>
			<SlideFade in>
				{isLoading ? (
					<Flex
						justify='center'
						mb={10}
					>
						<Skeleton
							height='30px'
							width='200px'
							borderRadius='md'
						/>
					</Flex>
				) : (
					<Text
						color={'white'}
						dir='rtl'
						marginBottom={'77px'}
						fontWeight={500}
						fontSize={32}
					>
						{data?.name}
					</Text>
				)}
			</SlideFade>
			<Box
				w='100%'
				overflowX={'auto'}
				maxW={style['pc-view']}
				bg='#fff'
				boxShadow='0px 0px 25px rgba(62, 62, 62, 0.08)'
				rounded='10px'
				padding={'30px'}
			>
				<Text
					mb={'30px'}
					fontWeight='400'
					color='#a9a9a9'
				>
					{UtilSrvice.timestampToDDMMYYYY(new Date().getTime())}
				</Text>

				{isLoading ? (
					<>
						<Table size='sm'>
							<Thead borderBottom={'1.5px solid #DFDFDF'}>
								<Tr>
									<Th
										fontSize={'16px'}
										fontWeight={700}
										lineHeight={'29px'}
										paddingBottom='12px'
										textAlign='start'
									>
										Employee
									</Th>
									<Th
										fontSize={'16px'}
										fontWeight={700}
										lineHeight={'29px'}
										paddingBottom='12px'
										textAlign='start'
									>
										Progress
									</Th>
									<Th
										fontSize={'16px'}
										fontWeight={700}
										lineHeight={'29px'}
										paddingBottom='12px'
										textAlign='start'
									>
										Role
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{[...Array(3)].map((_, i) => (
									<Tr
										key={i}
										rounded={'10px'}
										border={'none'}
									>
										<Td
											bg={i % 2 === 0 ? 'white' : 'white'}
											border={'none'}
											roundedRight='10px'
											textAlign='start'
										>
											<Skeleton
												height='10px'
												width='40%'
											/>
										</Td>
										<Td
											bg={i % 2 === 0 ? 'white' : '#FFF'}
											border={'none'}
										>
											<Flex
												justify='start'
												gap={1}
											>
												<SkeletonCircle size='10' />
											</Flex>
										</Td>
										<Td
											bg={i % 2 === 0 ? 'white' : '#FFF'}
											border={'none'}
											textAlign='start'
											roundedLeft='10px'
										>
											<Flex
												justify='start'
												gap={1}
											>
												<SkeletonText
													mt='4'
													noOfLines={1}
													spacing='4'
													width={'10px'}
													height={'1px'}
												/>
												<SkeletonText
													mt='4'
													noOfLines={1}
													spacing='4'
													width={'10px'}
													height={'1px'}
												/>
											</Flex>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</>
				) : (
					<>
						<Table size='sm'>
							<Thead borderBottom={'1.5px solid #DFDFDF'}>
								<Tr>
									<Th
										fontSize={'16px'}
										fontWeight={700}
										lineHeight={'29px'}
										paddingBottom='12px'
										textAlign='start'
									>
										{t('exposureDetailed.employee')}
									</Th>

									<Th
										fontSize={'16px'}
										fontWeight={700}
										lineHeight={'29px'}
										paddingBottom='12px'
										textAlign='start'
									>
										{t('exposureDetailed.progress')}
									</Th>
									<Th
										fontSize={'16px'}
										fontWeight={700}
										lineHeight={'29px'}
										paddingBottom='12px'
										textAlign='start'
									>
										{t('exposureDetailed.role')}
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{data?.employeeExposures
									?.sort((a, b) => a.exposure - b.exposure)
									// TODO: show only related employees (if the emplyoee has a role with task of a risk)
									.map((empl, i) => (
										<Employee
											{...empl}
											index={i}
											role={role}
											data={data}
											key={empl.id}
										/>
									))}
							</Tbody>
						</Table>
					</>
				)}
			</Box>
		</Container>
	)
}

export default ExposureDetailed
