import type { CompanyWithRoles } from '../../types/server/generics/company'
import { useListCompanies, useReadCompany } from '../../services/company-service'
import { ArrowDownIcon } from '../../assets/ArrowDownIcon'
import { RangePicker } from '../../components/RangePicker'
import { DownloadIcon } from '../../assets/DownloadIcon'
import { getMimeType } from '../../services/mime'
import { useTranslation } from 'react-i18next'
import { appConfig } from '../../configs/app'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import {
	Box,
	Button,
	Container,
	Text,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Table,
	Tbody,
	Thead,
	Th,
	Tr,
	Td,
	useMediaQuery,
	Skeleton,
} from '@chakra-ui/react'
import ReportsService, { useListCompanyReports } from '../../services/reports-service'
import style from '../../assets/style.json'

const FileSaver = require('file-saver')

// const mime = require('mime');

const Reports = () => {
	const { t } = useTranslation()
	const [isPc] = useMediaQuery('(min-width: 1300px)')
	let { companyId } = useParams()
	// const [minDate, setMinDate] = useState<Date | undefined>(undefined)
	// const [maxDate, setMaxDate] = useState<Date | undefined>(undefined)

	const [downloadPath, setDownloadPath] = useState('')
	const [downloadCompanyId, setDownloadCompanyId] = useState('')
	const [downloadEmployeeId, setDownloadEmployeeId] = useState('')
	const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()])
	// const [loading, setLoading] = useState(false)

	const [currentCompanyId, setCurrentCompanyId] = useState(companyId)

	const { data: selectedCompany, isLoading: isLoadingCompany } = useReadCompany(
		companyId || currentCompanyId ? currentCompanyId : undefined
	)
	const { data: companies, isLoading: isLoadingCompanies } = companyId ? {} : useListCompanies()

	const { data: reports } = useListCompanyReports(currentCompanyId)

	const isLoading = isLoadingCompany || isLoadingCompanies

	// const load = async () => {
	// 	if (companyId !== undefined) {
	// 		const company = await CompanyService.read(companyId)
	// 		setSelectedCompany(company)
	// 	} else {
	// 		let companies = await CompanyService.list()
	// 		companies = await Promise.all(
	// 			companies.map(async (company: any) => {
	// 				return { ...company, reports: await ReportsService.list(company.id) }
	// 			})
	// 		)

	// 		setCompanies(companies)
	// 	}

	// 	setLoaded(true)
	// }

	console.log({ companies, selectedCompany, reports })

	const downloadFile = async (fileName: string, path: string) => {
		/*
		DO
		NOT
		TOUCH
		*/
		console.log(fileName)
		console.log(path)

		// now you can touch
		setDownloadPath(path)
		try {
			let response = await fetch(
				`${appConfig.apiUrl}/file?path=${path}&token=${localStorage.getItem('token')}&fileName=${fileName}`
			)
			let data = await response.blob()

			let splittedFileName = fileName.split('.')
			let extension = splittedFileName.at(splittedFileName.length - 1)
			console.log('before')

			let fixedData = new Blob([data], { type: getMimeType(extension ?? '') })
			console.log('after')
			FileSaver.saveAs(fixedData, fileName)
		} finally {
			setDownloadPath('')
		}
	}

	const onCompanySelection = async (companyId: string) => {
		const company = companies?.find((i) => i.id === companyId)
		if (!company) return

		updateValidDates(company)
		setCurrentCompanyId(company?.id)
	}

	const updateValidDates = async (company: CompanyWithRoles) => {
		const reports = await ReportsService.list(company.id)
		if (!reports) return

		const minDate = reports.reduce((acc: Date, report) => {
			const date = new Date(report.date)
			return date < acc ? date : acc
		}, new Date())
		const maxDate = reports.reduce((acc: Date, report) => {
			const date = new Date(report.date)
			return date > acc ? date : acc
		}, new Date())

		// setMinDate(minDate)
		// setMaxDate(maxDate)

		const currDate = new Date()
		currDate.setHours(0, 0, 0, 0)

		updateDates([currDate, currDate])
	}

	const updateDates = (dates: Date[]) => {
		// setLoading(true)
		// console.log(dates)
		setSelectedDates(dates)
		// setTimeout(() => {
		// setLoading(false)
		// }, 1000)
	}

	const downloadCompanyFile = async () => {
		if (!selectedCompany) return

		setDownloadCompanyId(selectedCompany.id)
		try {
			const response = await ReportsService.current(selectedCompany.id)
			await downloadFile(`${selectedCompany.id}.pdf`, response.filePath)
		} finally {
			setDownloadCompanyId('')
		}
	}

	const downloadEmployeeFile = async (employeeId: string) => {
		setDownloadEmployeeId(employeeId)
		try {
			const response = await ReportsService.getForEmployee(employeeId)
			downloadFile(`${employeeId}.pdf`, response.filePath)
		} finally {
			setDownloadEmployeeId('')
		}
	}

	// useEffect(() => {
	// 	load()
	// }, [])

	return (
		<Container
			position='absolute'
			top={'90px'}
			maxW='full'
			minH='100vh'
			py={20}
			centerContent
		>
			<Text
				color={'white'}
				dir='rtl'
				marginBottom={'77px'}
				fontWeight={500}
				fontSize={32}
			>
				{!companies && selectedCompany ? t('reports.reportsList') : t('reports.history')}
			</Text>
			<Box
				w='100%'
				boxShadow='0px 0px 25px rgba(62, 62, 62, 0.08)'
				maxW={style['pc-view']}
				p={'21px'}
				rounded='10px'
				bg='white'
			>
				{!companies && selectedCompany && (
					<Box
						display='flex'
						rounded={'10px'}
						gap='21px'
						padding='21px'
						bg='#F5F8FA'
						flexDirection={isPc ? 'row' : 'column'}
					>
						<Box
							display={'flex'}
							width='100%'
							flexDirection={isPc ? 'row' : 'column'}
							justifyContent={isPc ? 'space-between' : 'center'}
							alignItems='center'
							gap='16px'
						>
							<Box>
								<Text
									textAlign={isPc ? 'start' : 'center'}
									color={'0E314E'}
									fontWeight='600'
									fontSize='22px'
								>
									{selectedCompany.name}
								</Text>
							</Box>
							<Button
								color={'white'}
								minW={isPc ? '200px' : 'full'}
								height='60px'
								textAlign='center'
								border='3px solid #FFFFFF'
								background={'#3FB2D2'}
								fontSize='18px'
								fontWeight={700}
								rounded={'100px'}
								filter='drop-shadow(0px 4px 30px rgba(63, 178, 210, 0.44))'
								display='flex'
								gap='8px'
								isLoading={downloadCompanyId === selectedCompany.id}
								isDisabled={downloadCompanyId === selectedCompany.id}
								alignItems='center'
								onClick={() => downloadCompanyFile()}
							>
								{t('reports.downloadReport')}
								<DownloadIcon />
							</Button>
						</Box>
					</Box>
				)}

				<iframe
					style={{ display: 'none' }}
					id='preview-iframe'
					width='100%'
					height='600px'
				/>

				{isLoading ? (
					<Box className='flex w-full gap-3'>
						<Skeleton
							height='50px'
							width={'80%'}
						/>
						<Skeleton
							height='50px'
							width={'20%'}
						/>
					</Box>
				) : (
					<>
						{companies && (
							<Box
								display='flex'
								rounded={'10px'}
								gap='21px'
								padding='21px'
								bg='#F5F8FA'
								flexDirection={isPc ? 'row' : 'column'}
							>
								<Box flexGrow={1}>
									<Text
										color={'#aaa'}
										fontSize='14px'
									>
										{t('reports.companyName')}
									</Text>
									<Box position={'relative'}>
										<Menu size={'full'}>
											<MenuButton
												bg={'#fff'}
												border={'1.2521px solid #E2E2E2'}
												rounded='10px'
												height={'54px'}
												fontSize='18px'
												paddingInline={'13px'}
												width='100%'
											>
												<Box
													justifyContent='space-between'
													alignItems={'center'}
													display={'flex'}
												>
													<Text
														fontSize='18px'
														fontWeight='700'
													>
														{selectedCompany
															? `${selectedCompany.name}`
															: t('reports.chooseCompany')}
													</Text>
													<ArrowDownIcon />
												</Box>
											</MenuButton>

											<MenuList
												overflowY='auto'
												maxH={'300px'}
												className='history-choose-company'
												width='100%'
											>
												{companies.map((company) => {
													return (
														<MenuItem
															// color="red.600"
															_hover={{
																bg: '#F5F8FA',
															}}
															display='flex'
															fontSize='18px'
															fontWeight='700'
															key={company.id}
															onClick={() => onCompanySelection(company.id)}
														>
															{company.name}
														</MenuItem>
													)
												})}
											</MenuList>
										</Menu>
									</Box>
								</Box>
								<Box>
									<Text
										color={'#aaa'}
										fontSize='14px'
									>
										{t('reports.dateRange')}
									</Text>
									<RangePicker
										selectedDates={selectedDates}
										setSelectedDates={updateDates}
									/>
								</Box>
							</Box>
						)}

						{companies && selectedCompany && (
							<>
								{/* {loading ? ( */}
								{isLoading ? (
									<Box className='flex w-full gap-3'>
										<Skeleton
											height='50px'
											width={'100%'}
										/>
									</Box>
								) : (
									<Table size='sm'>
										<Thead borderBottom={'1.5px solid #DFDFDF'}>
											<Tr>
												<Th
													fontSize={'16px'}
													fontWeight={700}
													lineHeight={'29px'}
													paddingBottom='12px'
													textAlign='start'
												>
													{t('reports.date')}
												</Th>
												<Th
													fontSize={'16px'}
													fontWeight={700}
													lineHeight={'29px'}
													paddingBottom='12px'
													textAlign='start'
												>
													{t('reports.downloadReport')}
												</Th>
											</Tr>
										</Thead>
										<Tbody>
											{(reports || [])
												.filter((i) => {
													const reportDate = new Date(i.date)
													reportDate.setHours(0, 0, 0, 0)

													const startDate = selectedDates[1]
														? new Date(selectedDates[1])
														: undefined

													const endDate = selectedDates[0]
														? new Date(selectedDates[0])
														: undefined

													if (startDate) startDate.setHours(0, 0, 0, 0)
													if (endDate) endDate.setHours(0, 0, 0, 0)

													console.log({ reportDate, selectedDates, startDate, endDate })

													return (
														(!startDate || reportDate >= startDate) &&
														(!endDate || reportDate <= endDate)
													)
												})
												.map((report, i) => {
													return (
														<Tr
															key={`report-${i}`}
															rounded={'10px'}
															border={'none'}
															position='relative'
														>
															<Td
																minWidth={150}
																roundedRight={'10px'}
																bg={i % 2 === 1 ? 'white' : '#F5F8FA'}
																border={'none'}
																textAlign='start'
															>
																{new Date(report.date).toLocaleDateString('en-IL')}
															</Td>
															<Td
																minWidth={150}
																roundedLeft={'10px'}
																bg={i % 2 === 1 ? 'white' : '#F5F8FA'}
																border={'none'}
																textAlign='start'
															>
																<Button
																	flex={1}
																	onClick={() =>
																		downloadFile(report.fileName, report.filePath)
																	}
																	isLoading={downloadPath === report.filePath}
																	isDisabled={downloadPath === report.filePath}
																	bg='#3FB2D2'
																	color='white'
																	display='flex'
																	alignItems={'center'}
																	justifyContent='center'
																	boxShadow='0px 3.02222px 22.6667px rgba(63, 178, 210, 0.34)'
																	rounded={'4px'}
																	width='32px'
																	height='32px'
																>
																	<DownloadIcon />
																</Button>
															</Td>
														</Tr>
													)
												})}
										</Tbody>
									</Table>
								)}
							</>
						)}

						{!companies && selectedCompany && (
							<>
								<Table size='sm'>
									<Thead borderBottom={'1.5px solid #DFDFDF'}>
										<Tr opacity={0.7}>
											<Th
												fontSize={'16px'}
												fontWeight={700}
												lineHeight={'29px'}
												paddingBottom='12px'
												textAlign='start'
											>
												{t('reports.employeeName')}
											</Th>
											<Th
												fontSize={'16px'}
												fontWeight={700}
												lineHeight={'29px'}
												paddingBottom='12px'
												textAlign='start'
											>
												{t('reports.downloadReport')}
											</Th>
										</Tr>
									</Thead>

									<Tbody>
										{selectedCompany.employees
											.filter((e) => e.isActive)
											.map((employee, index) => {
												return (
													<Tr
														key={`employee-${index}`}
														rounded={'10px'}
														border={'none'}
														position='relative'
													>
														<Td
															roundedRight={'10px'}
															bg={index % 2 !== 1 ? 'white' : '#F5F8FA'}
															border={'none'}
															textAlign='start'
														>
															{employee.name}
														</Td>
														<Td
															roundedLeft={'10px'}
															bg={index % 2 !== 1 ? 'white' : '#F5F8FA'}
															border={'none'}
															textAlign='start'
														>
															<Button
																colorScheme='blue'
																isLoading={downloadEmployeeId === employee.id}
																isDisabled={downloadEmployeeId === employee.id}
																variant='link'
																onClick={() => downloadEmployeeFile(employee.id)}
																bg='#3FB2D2'
																color='white'
																boxShadow='0px 3.02222px 22.6667px rgba(63, 178, 210, 0.34)'
																rounded={'4px'}
																width='32px'
																height='32px'
															>
																<DownloadIcon />
															</Button>
														</Td>
													</Tr>
												)
											})}
									</Tbody>
								</Table>
							</>
						)}
					</>
				)}
			</Box>
			{companies && (
				<Box
					display='flex'
					rounded={'10px'}
					opacity={0.7}
					gap='21px'
					bg='#F5F8FA'
					boxShadow='0px 0px 25px rgba(62, 62, 62, 0.08)'
					marginBlock='30px'
					maxW={style['pc-view']}
					flexDirection={isPc ? 'row' : 'column'}
				>
					<Text
						fontSize={'14px'}
						textAlign='center'
						maxWidth={'600px'}
					>
						{t('reports.overview')}
					</Text>
				</Box>
			)}
		</Container>
	)
}

export default Reports
