import type { OnboardingQuestionnaireController } from '../types/server/endpoints/onboarding-questionnaire'
import type { CompanyController } from '../types/server/endpoints/company'
import type { BackendQuery } from '../types/server/utils'
import { appConfig } from '../configs/app'
import fetcher from '../utils/fetcher'
import Swal from 'sweetalert2'
import axios from 'axios'
import useSWR from 'swr'

export const useListCompanies = (query?: BackendQuery) => {
	const queryString = new URLSearchParams(query).toString()

	const fetch = useSWR<CompanyController['list']['returns']>(
		`${appConfig.apiUrl}/companies${queryString ? `?${queryString}` : ''}`,
		fetcher
	)

	const { data } = useSWR<number>(
		`${appConfig.apiUrl}/companies/count${queryString ? `?${queryString}` : ''}`,
		fetcher
	)

	return {
		...fetch,
		data: fetch.data || [],
		total: data || 0,
	}
}

export const useGetCompanies = (companies: string[]) => {
	const fetch = useSWR<CompanyController['list']['returns2']>(
		companies.length ? `${appConfig.apiUrl}/companies?companies=${companies.join(',')}` : undefined,
		fetcher
	)

	return {
		...fetch,
		data: fetch.data || [],
	}
}

export const useReadCompany = (id?: string) => {
	const fetch = useSWR<CompanyController['read']['returns']>(
		id ? `${appConfig.apiUrl}/company/${id}` : undefined,
		fetcher,
		{
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
			refreshInterval: 0,
			refreshWhenHidden: false,
			refreshWhenOffline: false,
		}
	)

	return {
		...fetch,
		data: fetch.data,
	}
}

export const useGetUnlistedUsers = (companyId?: string) => {
	const fetch = useSWR<CompanyController['listUnlistedUsers']['returns']>(
		companyId ? `${appConfig.apiUrl}/unlisted-users/${companyId}` : undefined,
		fetcher
	)

	return {
		...fetch,
		data: fetch.data || [],
	}
}

export const useGetOnboardingList = () => {
	const fetch = useSWR<OnboardingQuestionnaireController['list']['returns']>(
		`${appConfig.apiUrl}/onboarding-questionnaire`,
		fetcher
	)

	return {
		...fetch,
		data: fetch.data || [],
	}
}

export const useOnboardingQuestionnaire = (id?: string) => {
	const fetch = useSWR<OnboardingQuestionnaireController['getById']['returns']>(
		id ? `${appConfig.apiUrl}/onboarding-questionnaire/${id}` : undefined,
		fetcher
	)

	return {
		...fetch,
		data: fetch.data,
	}
}

// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
class CompanyService {
	static async read(id: string) {
		const response = await axios.get(`${appConfig.apiUrl}/company/${id}`, {
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		})
		const data = response.data
		return data
	}

	static async delete(name: string, id: string) {
		const result = await Swal.fire({
			title: `למחוק את החברה "${name}"?`,
			text: 'לאחר המחיקה לא יהיה ניתן לשחזר את המידע.',
			input: 'password',
			inputAttributes: {
				autocapitalize: 'off',
				placeholder: 'הקש סיסמה',
			},
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			cancelButtonText: 'ביטול',
			confirmButtonText: 'מחיקה',
		})
		if (result.isConfirmed) {
			console.log(result)

			try {
				await axios.delete(`${appConfig.apiUrl}/company/${id}`, {
					data: { password: result.value },
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				})
			} catch (err: any) {
				Swal.fire('', err.response.data.message, 'error')
			}
		}
	}

	static async copy(id: string, name: string) {
		await axios.post(
			`${appConfig.apiUrl}/company/${id}/duplicate`,
			{ id, name },
			{
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			}
		)
	}

	// -> useListCompanies
	static async list() {
		const response = await axios.get(`${appConfig.apiUrl}/companies`, {
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		})
		const data = response.data
		return data
	}

	// -> useGetCompanies
	static async get(companies: string[]) {
		const response = await axios.get(`${appConfig.apiUrl}/companies`, {
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			params: { companies: companies.join(',') },
		})
		const data = response.data
		return data
	}

	static async checkAdminCode(code: string) {
		await axios.post(`${appConfig.apiUrl}/company/checkAdminCode`, {
			data: { password: code },
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		})
	}

	static async modify(info: any = {}) {
		try {
			const response = await axios.post(`${appConfig.apiUrl}/company`, info, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})
			console.log(response)
			if (response.status > 400) {
			}

			const data = response.data
			return data
		} catch (err: any) {
			Swal.fire('שגיאה', err?.response?.data?.message, 'error')
			throw err
		}
	}

	static async getOnboardingList(info: any = {}) {
		try {
			const response = await axios.get(`${appConfig.apiUrl}/onboarding-questionnaire`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})

			const data = response.data
			return data
		} catch (err: any) {
			Swal.fire('שגיאה', err?.response?.data?.message, 'error')
			throw err
		}
	}

	static async getOnboardingQuestionnaire() {
		try {
			const response = await axios.get(`${appConfig.apiUrl}/my-onboarding-questionnaire`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})

			const data = response.data
			return data
		} catch (err: any) {
			Swal.fire('שגיאה', err?.response?.data?.message, 'error')
			throw err
		}
	}

	static async createOnboardingQuestionnaire(questions: any[], name: string, lang: string) {
		try {
			const response = await axios.post(
				`${appConfig.apiUrl}/onboarding-questionnaire`,
				{
					id: '',
					name: name,
					questions: questions.map((question) => {
						return {
							...question,
							id: '',
						}
					}),
					lang,
				},
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)

			const data = response.data
			return data
		} catch (err: any) {
			Swal.fire('שגיאה', err?.response?.data?.message, 'error')
			throw err
		}
	}

	static async updateOnboardingQuestionnaire(id: string, questions: any[], name: string, lang: string) {
		try {
			const response = await axios.put(
				`${appConfig.apiUrl}/onboarding-questionnaire/${id}`,
				{
					id: id,
					name: name,
					questions: questions.map((question) => {
						return {
							...question,
							id: '',
						}
					}),
					lang,
				},
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)

			const data = response.data
			return data
		} catch (err: any) {
			Swal.fire('שגיאה', err?.response?.data?.message, 'error')
			throw err
		}
	}

	static async deleteOnboardingQuestionnaire(id: string) {
		try {
			const response = await axios.delete(`${appConfig.apiUrl}/onboarding-questionnaire/${id}`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})

			const data = response.data
			return data
		} catch (err: any) {
			Swal.fire('שגיאה', err?.response?.data?.message, 'error')
			throw err
		}
	}

	// static async getUnlistedUsers(companyId: string) {
	// 	try {
	// 		const response = await axios.get(`${appConfig.apiUrl}/unlisted-users/${companyId}`, {
	// 			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
	// 		})

	// 		const data = response.data
	// 		return data
	// 	} catch (err: any) {
	// 		Swal.fire('שגיאה', err?.response?.data?.message, 'error')
	// 		throw err
	// 	}
	// }

	static async createUnlistedUsers(companyId: string, phones: string[]) {
		try {
			const response = await axios.post(
				`${appConfig.apiUrl}/unlisted-users`,
				{
					companyId,
					phones,
				},
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)

			const data = response.data
			return data
		} catch (err: any) {
			Swal.fire('שגיאה', err?.response?.data?.message, 'error')
			throw err
		}
	}

	static async deleteUnlistedUser(companyId: string, userId: string) {
		try {
			const response = await axios.post(
				`${appConfig.apiUrl}/delete-unlisted-user`,
				{
					companyId,
					userId,
				},
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)

			const data = response.data
			return data
		} catch (err: any) {
			Swal.fire('שגיאה', err?.response?.data?.message, 'error')
			throw err
		}
	}

	static async setCompanyOnboardingQuestionnaire(
		companyId: string,
		onboardingQuestionnaireId: string,
		onboardingIncludeRoleIds: string[]
	) {
		try {
			const response = await axios.post(
				`${appConfig.apiUrl}/set-company-onboarding-questionnaire`,
				{
					companyId,
					onboardingQuestionnaireId,
					onboardingIncludeRoleIds,
				},
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)

			const data = response.data
			return data
		} catch (err: any) {
			Swal.fire('שגיאה', err?.response?.data?.message, 'error')
			throw err
		}
	}

	// -> useOnboardingQuestionnaire
	static async getOnboardingQuestionnaireById(id: string) {
		try {
			const response = await axios.get(`${appConfig.apiUrl}/onboarding-questionnaire/${id}`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})

			const data = response.data
			return data
		} catch (err: any) {
			Swal.fire('שגיאה', err?.response?.data?.message, 'error')
			throw err
		}
	}
}

export default CompanyService
