import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Text,
  Table,
  Tbody,
  Thead,
  Th,
  Tr,
  Td,
  useMediaQuery,
  Skeleton,
  Input,
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';

import { appConfig } from '../../configs/app';
import style from '../../assets/style.json';
import { DownloadIcon } from '../../assets/DownloadIcon';
import { useTranslation } from 'react-i18next';
import { getMimeType } from '../../services/mime';
import Swal from 'sweetalert2';
import { SanctionsService } from '../../services/sanctions-service';

const FileSaver = require('file-saver');

const Sanctions = ({ userInfo }: { userInfo: any }) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  const [loading, setLoading] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [data, setData] = useState<any>({
    america: [],
    europe: [],
    interpol: [],
	other: [],
  });

  const handleFileInput = async (e: any) => {
    try {
      setLoadData(true);
      const file = e.target!.files[0];
      if (!file) return;
      if (file.name.endsWith('.xls') || file.name.endsWith('.xlsx')) {
        if (e.target.id === 'america') {
          await SanctionsService.setAmerica(file);
        }
        if (e.target.id === 'europe') {
          await SanctionsService.setEurope(file);
        }
        if (e.target.id === 'interpol') {
          await SanctionsService.setInterpol(file);
        }
        if (e.target.id === 'other') {
          await SanctionsService.setOther(file);
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: t('sanctions.errorTitle'),
          text: t('sanctions.errorText'),
        });
      }
    } finally {
      setLoadData(false);
    }
  };

  const handleSearch = async () => {
    if (query.length < 3) return;
    setLoading(true);
    try {
      const response = await SanctionsService.getByQuery(query);
      setData({
        america: response.america,
        europe: response.europe,
        interpol: response.interpol,
		other: response.other,
      });
      setIsSearch(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    //@ts-ignore
    <Container
      position="absolute"
      top={'90px'}
      maxW="full"
      minH="100vh"
      py={20}
      centerContent
    >
      <Text
        color={'white'}
        dir="rtl"
        marginBottom={'77px'}
        fontWeight={500}
        fontSize={32}
      >
        {t('sanctions.title')}
      </Text>
      <Box
        w="100%"
        boxShadow="0px 0px 25px rgba(62, 62, 62, 0.08)"
        maxW={style['pc-view']}
        p={'21px'}
        rounded="10px"
        bg="white"
      >
        <>
          <Box rounded={'10px'} padding="21px" bg="#F5F8FA">
            <Box
              gap="21px"
              flexDirection={isPc ? 'row' : 'column'}
              w={'100%'}
              display="flex"
            >
              <Box flexGrow={1}>
                <Text color={'#aaa'} fontSize="14px">
                  {t('sanctions.query')}
                </Text>
                <Box position={'relative'}>
                  <Input
                    bg={'#fff'}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    border={'1.2521px solid #E2E2E2'}
                    rounded="10px"
                    height={'54px'}
                    fontSize="18px"
                    paddingInline={'13px'}
                    width="100%"
                    placeholder={t('sanctions.placeholder')}
                  />
                </Box>
                {t('sanctions.minChars')}
              </Box>
              <Box>
                <Button
                  isDisabled={query.length < 3}
                  onClick={handleSearch}
                  mt="25px"
                >
                  {t('sanctions.search')}
                </Button>
              </Box>
            </Box>
            {userInfo?.companyId === '123' && (
              <Box flexWrap="wrap" display="flex" gap="21px" pt={'21px'}>
                <Button
                  isDisabled={loadData}
                  isLoading={loadData}
                  onClick={() => {
                    document.getElementById('america')?.click();
                  }}
                >
                  {t('sanctions.setAmerica')}
                </Button>
                <Button
                  isDisabled={loadData}
                  isLoading={loadData}
                  onClick={() => {
                    document.getElementById('europe')?.click();
                  }}
                >
                  {t('sanctions.setEurope')}
                </Button>
                <Button
                  isDisabled={loadData}
                  isLoading={loadData}
                  onClick={() => {
                    document.getElementById('interpol')?.click();
                  }}
                >
                  {t('sanctions.setInterpol')}
                </Button>
                <Button
                  isDisabled={loadData}
                  isLoading={loadData}
                  onClick={() => {
                    document.getElementById('other')?.click();
                  }}
                >
                  {t('sanctions.setOther')}
                </Button>
                {loadData && t('sanctions.loadData')}
              </Box>
            )}
          </Box>
          {loading ? (
            <Table mt="21px">
              <Thead borderBottom={'1.5px solid #DFDFDF'}>
                <Tr>
                  <Th
                    fontSize={'16px'}
                    fontWeight={700}
                    lineHeight={'29px'}
                    paddingBottom="12px"
                    textAlign="start"
                    position="sticky"
                    right={'0'}
                    zIndex={5}
                    bg="white"
                  >
                    <Skeleton height="20px" width="100px" />
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {[...Array(3)].map((_, i) => (
                  <Tr key={i} rounded={'10px'} border={'none'}>
                    <Td
                      cursor="pointer"
                      roundedRight="10px"
                      bg={i % 2 === 0 ? 'white' : '#FFF'}
                      border={'none'}
                      textAlign="start"
                      position="sticky"
                      right={'0'}
                      zIndex={5}
                    >
                      <Skeleton height="20px" width="100px" />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Box pt={'21px'} display="flex" flexDirection="column" gap="21px">
              {isSearch &&
              data.america.length === 0 &&
              data.europe.length === 0 &&
              data.other.length === 0 &&
              data.interpol.length === 0 ? (
                <Text color={'#aaa'}>{t('sanctions.noResults')}</Text>
              ) : (
                <Accordion allowMultiple>
                  {data.america.length > 0 && (
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            {t('sanctions.americaName')} (
                            {data.america.length > 100
                              ? '100+'
                              : data.america.length}
                            )
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Table size="sm">
                          <Tbody>
                            {data.america
                              .slice(0, 100)
                              .map((name: any, index: number) => {
                                return (
                                  <Tr
                                    key={`sanction-${index}`}
                                    rounded={'10px'}
                                    border={'none'}
                                    position="relative"
                                  >
                                    <Td
                                      roundedRight={'10px'}
                                      bg={index % 2 !== 1 ? 'white' : '#F5F8FA'}
                                      border={'none'}
                                      textAlign="start"
                                    >
                                      {name}
                                    </Td>
                                  </Tr>
                                );
                              })}
                          </Tbody>
                        </Table>
                      </AccordionPanel>
                    </AccordionItem>
                  )}

                  {data.europe.length > 0 && (
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            {t('sanctions.europeName')} (
                            {data.europe.length > 100
                              ? '100+'
                              : data.europe.length}
                            )
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Table size="sm">
                          <Tbody>
                            {data.europe
                              .slice(0, 100)
                              .map((name: any, index: number) => {
                                return (
                                  <Tr
                                    key={`sanction-${index}`}
                                    rounded={'10px'}
                                    border={'none'}
                                    position="relative"
                                  >
                                    <Td
                                      roundedRight={'10px'}
                                      bg={index % 2 !== 1 ? 'white' : '#F5F8FA'}
                                      border={'none'}
                                      textAlign="start"
                                    >
                                      {name}
                                    </Td>
                                  </Tr>
                                );
                              })}
                          </Tbody>
                        </Table>
                      </AccordionPanel>
                    </AccordionItem>
                  )}
                  {data.interpol.length > 0 && (
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            {t('sanctions.interpolName')} (
                            {data.interpol.length > 100
                              ? '100+'
                              : data.interpol.length}
                            )
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Table size="sm">
                          <Tbody>
                            {data.interpol
                              .slice(0, 100)
                              .map((name: any, index: number) => {
                                return (
                                  <Tr
                                    key={`sanction-${index}`}
                                    rounded={'10px'}
                                    border={'none'}
                                    position="relative"
                                  >
                                    <Td
                                      roundedRight={'10px'}
                                      bg={index % 2 !== 1 ? 'white' : '#F5F8FA'}
                                      border={'none'}
                                      textAlign="start"
                                    >
                                      {name}
                                    </Td>
                                  </Tr>
                                );
                              })}
                          </Tbody>
                        </Table>
                      </AccordionPanel>
                    </AccordionItem>
                  )}
                  {data.other.length > 0 && (
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            {t('sanctions.otherName')} (
                            {data.other.length > 100
                              ? '100+'
                              : data.other.length}
                            )
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Table size="sm">
                          <Tbody>
                            {data.other
                              .slice(0, 100)
                              .map((name: any, index: number) => {
                                return (
                                  <Tr
                                    key={`sanction-${index}`}
                                    rounded={'10px'}
                                    border={'none'}
                                    position="relative"
                                  >
                                    <Td
                                      roundedRight={'10px'}
                                      bg={index % 2 !== 1 ? 'white' : '#F5F8FA'}
                                      border={'none'}
                                      textAlign="start"
                                    >
                                      {name}
                                    </Td>
                                  </Tr>
                                );
                              })}
                          </Tbody>
                        </Table>
                      </AccordionPanel>
                    </AccordionItem>
                  )}
                </Accordion>
              )}
            </Box>
          )}

          {/* {!companies && selectedCompany && (
            <>
              <Table size="sm">
                <Thead borderBottom={'1.5px solid #DFDFDF'}>
                  <Tr opacity={0.7}>
                    <Th
                      fontSize={'16px'}
                      fontWeight={700}
                      lineHeight={'29px'}
                      paddingBottom="12px"
                      textAlign="start"
                    >
                      {t('reports.employeeName')}
                    </Th>
                    <Th
                      fontSize={'16px'}
                      fontWeight={700}
                      lineHeight={'29px'}
                      paddingBottom="12px"
                      textAlign="start"
                    >
                      {t('reports.downloadReport')}
                    </Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {selectedCompany.employees
                    .filter((e: any) => e.isActive)
                    .map((employee: any, index: number) => {
                      return (
                        <Tr
                          key={`employee-${index}`}
                          rounded={'10px'}
                          border={'none'}
                          position="relative"
                        >
                          <Td
                            roundedRight={'10px'}
                            bg={index % 2 !== 1 ? 'white' : '#F5F8FA'}
                            border={'none'}
                            textAlign="start"
                          >
                            {employee.name}
                          </Td>
                          <Td
                            roundedLeft={'10px'}
                            bg={index % 2 !== 1 ? 'white' : '#F5F8FA'}
                            border={'none'}
                            textAlign="start"
                          >
                            <Button
                              colorScheme="blue"
                              variant="link"
                              bg="#3FB2D2"
                              color="white"
                              boxShadow="0px 3.02222px 22.6667px rgba(63, 178, 210, 0.34)"
                              rounded={'4px'}
                              width="32px"
                              height="32px"
                            >
                              <DownloadIcon />
                            </Button>
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </>
          )} */}
        </>
      </Box>
      {/* {companies && (
        <Box
          display="flex"
          rounded={'10px'}
          opacity={0.7}
          gap="21px"
          bg="#F5F8FA"
          boxShadow="0px 0px 25px rgba(62, 62, 62, 0.08)"
          marginBlock="30px"
          maxW={style['pc-view']}
          flexDirection={isPc ? 'row' : 'column'}
        >
          <Text fontSize={'14px'} textAlign="center" maxWidth={'600px'}>
            {t('reports.overview')}
          </Text>
        </Box>
      )} */}
      <input
        type="file"
        id="america"
        style={{ visibility: 'hidden', width: 0, height: 0 }}
        onChange={handleFileInput}
      />
      <input
        type="file"
        id="europe"
        style={{ visibility: 'hidden', width: 0, height: 0 }}
        onChange={handleFileInput}
      />
      <input
        type="file"
        id="interpol"
        style={{ visibility: 'hidden', width: 0, height: 0 }}
        onChange={handleFileInput}
      />
      <input
        type="file"
        id="other"
        style={{ visibility: 'hidden', width: 0, height: 0 }}
        onChange={handleFileInput}
      />
    </Container>
  );
};

export default Sanctions;
